.subtitles .analytic-P{margin-bottom: 16px;}
.subtitles .themeTable .videoInfo .light-p{font-size: 16px; line-height: 22px; font-weight: 300; opacity: 0.4;}
.fw3{font-weight: 300 !important; opacity: 0.4 !important;}
.subtitles .themeTable table {min-width: 1000px;}
.subtitle-modal .modalSelect {max-width: 100%;margin: 0 0 20px;}
.subtitle-modal .modal-content .btn-close {position: absolute;right: 35px;top: 60%;transform: translateY(-50%);}
.subtitle-modal .modal-header {position: relative;}
.subtitle-modal .modalSelect span {font-size: 40px;color: #FFFFFF;opacity: 0.6;}
.subtitle-modal .checkboxText h4 {margin-left: 15px;}
@media (max-width:1699px) {
    .subtitles .analytic-P{margin-bottom: 10px;}
    .subtitles .themeTable .videoInfo .light-p{font-size: 13px; line-height: 19px; }
}

