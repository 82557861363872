.mainSidebar {height: calc(100vh - 95px);position: fixed;top: 95px;z-index: 999;}
.mainSidebar .sidebarLogo {align-items: center;background: #fd6f21;border-radius: 50%;color: #fff;cursor: pointer;display: flex;display: none;float: right;font-size: 22px;height: 65px;justify-content: center;margin: -20px;transition: all .4s ease-in-out;transition-delay: .2s;width: 65px;}
.mainSidebar .rotate {transform: rotate(-135deg);}
.mainSidebar .sidebarFlex {background: #fff;border-radius: 7px;display: flex;flex-direction: column;height: 100%;justify-content: space-between;min-height: 100%;min-width: 294px;overflow-y: auto;}
.mainSidebar .mobile-button {align-items: center;background: #fd6f21;border-radius: 0 8px 8px 0;box-shadow: 0 0 5px 3px #0d0f17;color: #fff;cursor: pointer;display: none;font-size: 16px;height: 30px;justify-content: center;position: absolute;right: -30px;top: 15px;transition: all .4s ease-in-out;transition-delay: .2s;width: 30px;z-index: 0;}
.mainSidebar .sidebarFlex>.sidebarLink {height: calc(100% - 57px);overflow-y: auto;}
.mainSidebar .sidebarStyle h3 {border-bottom: 1px solid #d5d5d5;color: #000;font-family: outfit;font-size: 20px;font-weight: 700;line-height: 24px;padding: 30px 0 30px 30px;text-transform: capitalize;}
.mainSidebar .sidebarStyle ul {list-style: none;margin-bottom: 0;padding-left: 0; padding-top: 10px;}
.mainSidebar .sidebarStyle li {margin-bottom: 10px;}
.mainSidebar .sidebarStyle li a,.mainSidebar .sidebarStyle li .sidebar-span {border-radius: 0 25px 25px 0;color: #000;display: block;font-family: outfit;font-size: 16px;font-weight: 500;line-height: 15px;padding: 0 0 0 25px;text-decoration: none;text-transform: capitalize;transition: all .4s ease-in-out;}
.mainSidebar .sidebarStyle li .active, 
.mainSidebar .sidebarStyle li a:hover {background: #0000 linear-gradient(98deg,#505050,#e37229) 0 0 no-repeat padding-box;color: #fff;}
.mainSidebar .sidebarStyle li a span, .mainSidebar .sidebarStyle li .sidebar-span span {display: inline-block;padding: 10px 0 10px 15px;width: 100%;}
.mainSidebar .sidebarStyle li a.active span, .sidebarStyle li a:hover span {border-color: #0000;}
.mainSidebar .sidebar-img {height: auto;padding: 4px;width: 30px;}
.mainSidebar .sidebar-img img {height: 100%;object-fit: cover;width: 100%;}
.mainSidebar .sidebarStyle ul li a.active img, .mainSidebar .sidebarStyle ul li a:hover img {filter: invert(1);}
.mainSidebar .memberImg img {max-width: 180px;}
.sitePages {float: right;padding: 30px 30px 59px;}
.sitePages-main-width {overflow-y: auto;width: calc(100% - 295px);}
.sitePages .dashboardHeader {height: 100%;margin-bottom: 12px;}
/* content right to left */
.rtl .mainSidebar{right: 0;}
.rtl .sitePages{float: left;}
.rtl .mainSidebar .sidebarStyle h3{text-align: right; padding: 30px 30px 30px 0px;}
.rtl .mainSidebar .sidebarStyle li span{flex-direction: row-reverse;}
.rtl .mainSidebar .sidebarStyle li a, .rtl .mainSidebar .sidebarStyle li .sidebar-span{padding: 0px 25px 0px 0px; border-radius: 25px 0px 0px 25px;}
/*RESPONSIVENESS*/
@media (max-width:1699px) {
   .sitePages-main-width { width: calc(100% - 250px);height: calc(100vh - 92px);}
   .mainSidebar{top: 91px;}
   .mainSidebar .sidebarStyle h3{ font-size: 18px; line-height: 24px; padding: 20px 0px 20px 20px;}
   .mainSidebar .sidebarStyle ul li a { font-size: 14px; line-height: 20px; padding: 0px 0px 0px 15px;}
   .mainSidebar .sidebarStyle ul li a span { padding: 8px 0px 8px 0px; padding-left: 13px;}
   .mainSidebar .sidebarStyle ul li a img{width: 15px;}
   .mainSidebar .sidebarLogo{width: 45px; height:45px;}
   .mainSidebar .sidebarLogo svg{width: 15px;}
   .mainSidebar .sidebarFlex {min-width: 247px;}
   .memberImg img{max-width: 120px;}
}
@media (max-width:1399px) {
   .mainSidebar .sidebarStyle h3{ font-size: 16px; line-height: 20px; padding: 15px 0px 15px 15px;}
   .mainSidebar .sidebarLogo{width: 35px; height:35px;}
   .mainSidebar .sidebarLogo svg{width: 10px;}
   .mainSidebar .sitePages { padding: 20px 20px 35px 20px;}
   .mainSidebar .sidebarStyle.w2{min-width: 50px;}
   .mainSidebar .logout svg { font-size: 20px;}
   .mainSidebar .sidebarStyle .orange-btn{margin-top: 20px;}
   .sitePages-width{width: calc(100% - 100px);}
   .sitePages-main-width {height: calc(100vh - 82px);}
   .sitePages-width{height: calc(100vh - 82px);}
   .v-player-container .v-custom-sitecards h2 {font-size: 26px;line-height: 32px;}
   .v-player-container .exploreCard .exploreImg img{height: 130px;}
   .v-custom-sitecards.recomemded-videos .exploreImg img{height:100%}
}
@media (max-width:1199px) {
   .v-player-container .v-custom-sitecards h2 {font-size: 22px;line-height: 28px;}
   .sitePages {padding: 30px 20px 59px;}
}
@media (max-width:991px) {
   .sitePages-main-width { width: 100%; padding-left: 30px;}
   .mainSidebar .sidebarStyle.w2 ul li a { padding: 0px 0px 0px 7px;}
   .mainSidebar {top: 85px;margin: 0px;position: absolute;height: calc(100vh - 85px);left: -250px;transition: all ease-in-out 0.3s;}
   .mainSidebar.show {left: 0px;transition: all ease-in-out 0.3s;}
   .mainSidebar .sidebarLogo {display: none;}
   .mainSidebar .mobile-button {display: flex;}
}
 @media (max-width:575px){
    .sitePages-width {width: calc(100% - 85px);}
 }
 @media (max-width:467px) {
   .mainSidebar {top: 80px; height: calc(100vh - 80px);}
   .sitePages-width{width: calc(100% - 90px);}
   .mainSidebar .sidebarStyle.w2 ul li a{margin-right: 0;}
 }
 @media (max-width:379px) {
    .sitePages {padding: 20px 10px 35px 10px;}
 }
 @media (max-width: 319px) {
   .mainSidebar {height: calc(100vh - 122px);}
 }