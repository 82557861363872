.blogs-sec {padding: 30px 0 0 0;}
.blogs-banner {margin-bottom: 120px;position: relative;}
.blog-img {position: relative;height: 690px;}
.blog-img img {border-radius: 24px;}
.blog-img::before {content: "";position: absolute;border-radius: 24px;top: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.5);}
.blogs-banner .blogs-banner-content {position: absolute;left: 80px;bottom: 60px;}
.blogs-banner .blogs-banner-content h1 {max-width: 735px;margin-bottom: 30px;color: #fff;}
.blogs-banner .blogs-banner-content .date {font-size: 18px;line-height: 23px;color: #f9f2ff;}
.trending-blogs h3 {font-size: 42px;line-height: 53px;font-weight: 700;}
.trending-blogs .trending-links {padding: 0;list-style: none;}
.trending-blogs .trending-links li:not(:last-child) {margin-right: 60px;}
.trending-blogs .trending-links li a {font-size: 20px;line-height: 25px;font-weight: 400;color: #505050;text-decoration: none;transition: all 0.3s ease-in-out;}
.trending-blogs .trending-links li a.active,
.trending-blogs .trending-links li a:hover {color: #FD6F21;}
.trending-blogs .heading-links {padding-bottom: 40px;/* border-bottom: 1px solid rgba(225, 225, 225, 0.15); */margin-bottom: 30px;}
.trending-blogs .trending-wrapper .img {position: relative;}
.trending-blogs .trending-wrapper .img img {border-radius: 20px;}
.trending-blogs .trending-wrapper .img::before {content: "";position: absolute;top: 0;background: transparent linear-gradient(180deg, #97979700 0%, #000000 100%) 0%  0% no-repeat padding-box;width: 100%;height: 100%;border-radius: 20px;}
.trending-blogs .content{padding:30px 0;}
.trending-blogs .content span.liftstyle-tag {font-size: 14px;line-height: 19px;padding: 10px 13px;border-radius:4px;background-color: #074f57;color:#fff;border-radius:4px}
.trending-blogs  .content h4 a {font-size: 32px;line-height: 40px;font-weight: 600;max-width: 433px;margin-top: 20px;height:80px;-webkit-line-clamp:2;display:-webkit-box;-webkit-box-orient:vertical;text-overflow: ellipsis;overflow:hidden;color:#505050;transition: all ease-in-out 0.3s;;}
.trending-blogs  .content h4 a:hover{color:#FD6F21;}
.trending-blogs .content p.date {font-size: 14px;line-height: 16px;font-weight: 400;}
.trending-blogs .trending-wrapper {position: relative;overflow: hidden;color: #fff;}
.trending-blogs .trending-wrapper .content {position: absolute;bottom: 30px;left: 30px;}
.blogs-sec .related-posts {margin-top: 23px;margin-bottom: 0;}
.blogs-sec .related-posts .heading-sec {font-size: 24px;line-height: 30px;font-weight: 600;padding-bottom: 40px;border-bottom: 1px solid rgba(225, 225, 225, 0.15);margin-bottom: 30px;}
.blogs-sec .related-posts .related-post:last-child {margin-bottom: 0;}
.blogs-sec .trending-blogs {margin-bottom: 90px;}
.trending-news .trending-news-wrapper h4 {font-size: 24px;line-height: 30px;font-weight: 600;padding-bottom: 17px;border-bottom: 1px solid rgba(225, 225, 225, 0.15);margin-bottom: 40px;}
.trending-news .trending-news-wrapper .img {height: 280px;margin-bottom: 30px;}
.trending-news .trending-news-wrapper .date {font-size: 16px;line-height: 20px;font-weight: 400;color: #505050;}
.trending-news .trending-news-wrapper .heading {font-size: 24px;line-height: 30px;margin-bottom: 19px;max-width: 362px;font-weight: 600;color: #000000;transition: all 0.3s ease-in-out;}
.trending-news .trending-news-wrapper .heading:hover{color: #fd6f21;}
.trending-news .trending-news-wrapper .description {margin-bottom: 20px;font-size: 17px;line-height: 20px;font-weight: 400;color: #707070;}
.trending-news .trending-news-wrapper .read-more-btn a {font-size: 14px;line-height: 18px;padding: 13px 22px; display: inline-block; background-color:  #fd6f21;border: 1px solid #fd6f21;border-radius: 6px;color: #fff;transition: all 0.3s ease-in-out;}
.trending-news .trending-news-wrapper .read-more-btn a:hover {background-color: transparent;color: #FD6F21;}
.trending-news .trending-news-wrapper .read-more-btn button:hover {background-color: #1e222f;}
.blogs-sec .related-posts2 .col-lg-4 {margin-bottom: 30px;}
.blogs-sec .related-posts2 {margin-bottom: 90px;margin-top: 0;}
.perspiciatis-images {margin-bottom: 80px;}
.perspiciatis-images a:hover {color: #FD6F21;}
.perspiciatis-images .col-lg-6 {margin-bottom: 20px;}
.perspiciatis-images .perspiciatis-wrapper {position: relative;}
.perspiciatis-images .perspiciatis-wrapper .img {position: relative;height: 600px;}
.perspiciatis-images .perspiciatis-wrapper .img::before {content: "";position: absolute;top: 0;width: 100%;height: 100%;border-radius: 20px;background-color: rgba(0, 0, 0, 0.3);}
.perspiciatis-images .perspiciatis-wrapper .content {position: absolute;bottom: 40px;left: 40px;}
.perspiciatis-images .perspiciatis-wrapper .content .date {font-size: 16px;line-height: 20px;font-weight: 400;color: rgba(225, 225, 225, 0.6);margin-bottom: 12px;}
.perspiciatis-images .perspiciatis-wrapper .content .heading {font-size: 38px;line-height: 48px;font-weight: 600;}
.infor.perspiciatis-images .perspiciatis-wrapper .img img {border-radius: 20px;}
.information-technologies-wrapper .single-img {position: relative;margin-bottom: 56px;}
.information-technologies-wrapper .single-img .img {position: relative;height: 540px;}
.information-technologies-wrapper .single-img img {border-radius: 20px;}
.information-technologies-wrapper .single-img .img::before {content: "";position: absolute;top: 0;border-radius: 20px;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.35);}
.information-technologies-wrapper .single-img .content {position: absolute;bottom: 47px;left: 30px;}
.information-technologies-wrapper .single-img .content h2 {font-size: 42px;line-height: 53px;font-weight: 600;max-width: 740px;margin-bottom: 15px;color: #fff;}
.information-technologies-wrapper .single-img .content .date {font-size: 16px;line-height: 20px;font-weight: 400;color: #fff;}
.information-technologies-wrapper .related-blogs2 .related-blogs-wrapper .img {margin-bottom: 30px;height: 364px;}
.information-technologies-wrapper .related-blogs2 .blog-heading {font-size: 24px;line-height: 30px;max-width: 362px;height:63px;-webkit-line-clamp:2;display:-webkit-box;-webkit-box-orient:vertical;text-overflow: ellipsis;overflow:hidden;color:#505050;transition: all ease-in-out 0.3s;}
.information-technologies-wrapper .related-blogs2 .blog-heading:hover {color:#FD6F21;}
.information-technologies h3.heading {font-size: 24px;line-height: 30px;font-weight: 600;padding-bottom: 17px;border-bottom: 1px solid rgba(225, 225, 225, 0.15);margin-bottom: 50px;}
.trending-news .trending-news-wrapper {margin-bottom: 40px;}
.information-technologies-wrapper .related-blogs-wrapper .img img {border-radius: 20px;}
.blog-details-sec .related-blogs {background: #fff;border-radius: 10px;box-shadow: 0 0 14px #0000001a;padding: 30px 33px;}
/* content right to left */
.rtl .blogs-banner .blogs-banner-content{left: auto; right: 80px;}
.rtl .blogs-banner .blogs-banner-content h1{text-align: right;}
.rtl .blogs-banner .blogs-banner-content .date{text-align: right;}
.rtl .trending-blogs h3{text-align: right;}
.rtl .trending-news .trending-news-wrapper .date{text-align: right;}
.rtl .trending-news .trending-news-wrapper .heading{text-align: right;display: block; max-width: 100%;}
.rtl .trending-news-wrapper .read-more-btn{display: flex; justify-content: flex-end;}
.rtl .heading-links{flex-direction: row-reverse;}
.rtl .trending-links{flex-direction: row-reverse;}
.rtl .trending-blogs .content { display: flex; flex-direction: column; align-items: flex-end;}
.rtl .trending-blogs .content h4 a{text-align: right;}
.rtl .catagories .category{flex-direction: row-reverse;}
.rtl .trending-blogs .trending-links li:not(:last-child) {margin-left: 60px; margin-right: 0;}
.rtl .perspiciatis-images .perspiciatis-wrapper .content{left: auto; right: 40px;}
.rtl .perspiciatis-images .perspiciatis-wrapper .content .heading{text-align: right;}
.rtl .perspiciatis-images .perspiciatis-wrapper .content .date{display: block; text-align: right;}
/*media queires*/
@media (max-width: 1599px) {
  .blog-img {height: 580px;}
  .blogs-banner .blogs-banner-content h1 {max-width: 600px;margin-bottom: 20px;}
  .blogs-banner .blogs-banner-content {position: absolute;left: 60px;bottom: 50px;}
  .blogs-banner,
  .blogs-sec .trending-blogs {margin-bottom: 80px;}
  .trending-blogs .trending-links li:not(:last-child) {margin-right: 40px;}
  .trending-blogs .heading-links,
  .blogs-sec .related-posts .heading-sec {padding-bottom: 30px;}
  .trending-blogs .trending-wrapper .content h4 {font-size: 30px;line-height: 37px;margin-top: 10px;}
  .trending-blogs h3 {font-size: 40px;line-height: 50px;}
  .trending-blogs .trending-links li a {font-size: 18px;line-height: 23px;}
  .blogs-sec .related-posts .heading-sec {font-size: 22px;line-height: 28px;}
  .trending-news .trending-news-wrapper h4 {font-size: 22px;line-height: 28px;padding-bottom: 14px;margin-bottom: 30px;}
  .trending-news .trending-news-wrapper .img {height: 250px;}
  .trending-news .trending-news-wrapper .heading {font-size: 22px;line-height: 28px;margin-bottom: 14px;}
  .blogs-sec .related-posts2 {margin-bottom: 60px;}
  .perspiciatis-images .perspiciatis-wrapper .img {height: 510px;}
  .perspiciatis-images .perspiciatis-wrapper .content .heading {font-size: 35px;line-height: 44px;}
  .perspiciatis-images {margin-bottom: 60px;}
  .information-technologies h3.heading {font-size: 22px;line-height: 28px;padding-bottom: 14px;margin-bottom: 40px;}
  .information-technologies-wrapper .single-img .img {height: 450px;}
  .information-technologies-wrapper .single-img .content h2 {font-size: 40px;line-height: 48px;margin-bottom: 10px;color: #fff;}
  .information-technologies-wrapper .single-img .content {bottom: 35px;left: 25px;}
  .information-technologies-wrapper .related-blogs2 .related-blogs-wrapper .img {height: 284px;}
  .information-technologies-wrapper .related-blogs2 .related-blogs-wrapper .blog-heading {font-size: 22px;line-height: 28px;}
}
@media (max-width: 1399px) {
  .blog-img {height: 500px;}
  .blogs-banner .blogs-banner-content {position: absolute;left: 50px;bottom: 40px;}
  .blogs-banner,
  .blogs-sec .trending-blogs,
  .blogs-sec .related-posts2,
  .perspiciatis-images {margin-bottom: 50px;}
  .trending-blogs .trending-links li:not(:last-child) {margin-right: 22px;}
  .trending-news .trending-news-wrapper .img {height: 220px;}
  .perspiciatis-images .perspiciatis-wrapper .img {height: 470px;}
  .information-technologies-wrapper .single-img .img {height: 420px;}
  .information-technologies-wrapper .related-blogs2 .related-blogs-wrapper .img {height: 244px;}
}
@media (max-width: 1199px) {
  .blog-img {height: 460px;}
  .trending-blogs .heading-links {flex-direction: column;}
  .blogs-sec .related-posts .related-post {margin-bottom: 25px;}
  .trending-blogs .trending-wrapper .content h4 {font-size: 26px;line-height: 32px;}
  .trending-blogs .trending-wrapper .content {bottom: 20px;left: 15px;}
  .perspiciatis-images .perspiciatis-wrapper .img {height: 420px;}
  .perspiciatis-images .perspiciatis-wrapper .content .heading {font-size: 32px;line-height: 40px;}
  .perspiciatis-images .perspiciatis-wrapper .content {bottom: 30px;left: 20px;}
  .information-technologies-wrapper .single-img .img {height: 380px;}
  .information-technologies-wrapper .single-img .content h2 {font-size: 36px;line-height: 44px;}
  .information-technologies-wrapper .single-img .content {bottom: 30px;left: 20px;}
  .information-technologies-wrapper .related-blogs2 .related-blogs-wrapper .img {height: 204px;}
}
@media (max-width: 991px) {
  .blog-img {height: 370px;}
  .trending-blogs h3 {font-size: 38px;line-height: 46px;}
  .trending-blogs .heading-links,
  .blogs-sec .related-posts .heading-sec {padding-bottom: 20px;}
  .blogs-sec .related-posts3 .related-post {width: 50%;}
  .blogs-sec .related-posts-wrapper {display: flex;flex-wrap: wrap;flex-direction: column;}
  .trending-news .trending-news-wrapper .img {height: 160px;}
  .perspiciatis-images .perspiciatis-wrapper .content .heading {font-size: 26px;line-height: 34px;}
  .perspiciatis-images .perspiciatis-wrapper .img {height: 320px;}
  .information-technologies h3.heading {margin-bottom: 30px;}
  .information-technologies-wrapper .single-img .content h2 {font-size: 34px;line-height: 40px;}
  .information-technologies-wrapper .related-blogs2 .related-blogs-wrapper .img {height: auto;}
  .blogs-banner .blogs-banner-content {left: 30px;bottom: 20px;}
}
@media (max-width: 767px) {
  .trending-blogs .trending-wrapper .img {height: auto;}
  .trending-blogs-wrapper .col-md-6 {margin-bottom: 20px;}
  .trending-blogs .trending-wrapper .content h4 {max-width: 550px;}
  .trending-blogs .trending-wrapper .content p.date {font-size: 15px;line-height: 19px;}
  .trending-news .trending-news-wrapper .img {height: auto;}
  .trending-news .trending-news-wrapper .heading {max-width: 100%;}
  .trending-news .col-md-4 {margin-bottom: 20px;}
  .blogs-sec .related-posts3 .related-post {width: 100%;}
  .perspiciatis-images .col-md-6 {margin-bottom: 20px;}
}
@media (max-width: 575px) {
  .blogs-banner .blogs-banner-content h1 {font-size: 26px;line-height: 30px;margin-bottom: 10px;}
  .blog-img {height: 250px;}
  .blogs-banner .blogs-banner-content {left: 15px;bottom: 10px;}
  .blogs-banner .blogs-banner-content .date {font-size: 16px;line-height: 16px;}
  .trending-blogs h3 {font-size: 34px;line-height: 40px;}
  .trending-blogs .trending-links {flex-wrap: wrap;}
  .trending-blogs .trending-wrapper .content h4 {font-size: 24px;line-height: 28px;}
  .blogs-banner,
  .blogs-sec .trending-blogs,
  .blogs-sec .related-posts2,
  .perspiciatis-images {margin-bottom: 30px;}
  .trending-news .trending-news-wrapper .heading {font-size: 20px;line-height: 24px;}
  .trending-news .trending-news-wrapper .img {margin-bottom: 20px;}
  .trending-news .trending-news-wrapper .read-more-btn button {padding: 10px 16px;}
  .perspiciatis-images .perspiciatis-wrapper .img {height: auto;}
  .perspiciatis-images .perspiciatis-wrapper .content .heading {font-size: 24px;line-height: 30px;}
  .perspiciatis-images .perspiciatis-wrapper .content {bottom: 20px;left: 10px;}
  .information-technologies-wrapper .single-img .img {height: 320px;}
  .information-technologies-wrapper .single-img .content h2 {font-size: 28px;line-height: 32px;}
  .information-technologies-wrapper .single-img .content {bottom: 20px;left: 10px;}
  .information-technologies .related-blogs2 .col-sm-6 {margin-bottom: 20px !important;}
  .information-technologies-wrapper .related-blogs2 .related-blogs-wrapper .blog-heading {font-size: 20px;line-height: 24px;}
  .trending-blogs .trending-links li a {font-size: 16px;line-height: 19px;}
}
@media (max-width:367px) {
  .blogs-sec .related-posts .related-post{ flex-wrap: wrap;}
}
@media (max-width: 319.98px) {
  .perspiciatis-images .perspiciatis-wrapper .content {bottom: 10px;}
}