.package-card{background: #393C48 ; border: 2px solid transparent; border-radius: 20px; padding:40px 40px 30px 40px; text-align: left; height: 100%;display: flex; flex-direction: column; justify-content: space-between; transition: all ease-in-out .4s;cursor: pointer;align-items: self-start; position: relative;}
.package-card:hover {border-color: #FD6F21;transform: scale(1.02); position: relative; z-index: 99;}
.packageCardHeader{margin-bottom: 0px; padding-left: 0px; list-style: none; display: flex;flex-wrap: wrap; justify-content: space-between;}
.packageCardHeader > div{width:48%;}
.package-card .packageH3{font-size: 40px; line-height: 50px; font-weight: 700; color: #FD6F21; margin-bottom: 20px; display: flex; align-items: center;}
.package-card .packageH3 .h3Sub{color:#505050;display:inline-block;vertical-align: middle;}
.package-card .packageTitle{font-size: 16px; line-height: 20px; font-weight: 400; color: #505050; opacity: 0.6; margin-bottom: 1px; text-transform: uppercase;}
.h3Sub{font-size: 17px; line-height: 22px; font-weight: 400; color: #fff; text-transform: lowercase; margin-left: 5px;}
.package-ul{margin-bottom: 32px; padding-left: 0px; list-style: none;}
.package-ul li:not(:last-child){margin-bottom:10px;}
.package-ul li p{font-size: 20px; line-height: 30px; font-weight: 400; margin-bottom:10px; color: #fff; margin-left: 15px;}
.package-ul li{display: flex; align-items: center;}
.package-ul li::before{content: ''; display: block; width: 9px; height: 1px; background-color: #505050;}
.package-select-dropdown .dropdown-menu {background: #fff;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;}
.package-select-dropdown .dropdown-menu .dropdown-item {color: #505050;}
.package-select-dropdown .dropdown-menu .dropdown-item:hover {background-color: #FD6F21;color:#fff;}
.packages-sec .formStyle.package{max-width: unset;}
.packages-sec .formStyle{min-width: unset;}
.package-card button{line-height: 22px;font-size: 18px;padding: 17px 40px !important;display: inline-block;border-radius: 10px;font-weight: 700;}
.package-card .dropdown-toggle::after {margin-left: 10px;}
/* content right to left */
.rtl .packageCardHeader{flex-direction: row-reverse;}
.rtl .package-select-dropdown .dropdown-menu .dropdown-item{text-align: right;}
/*reessponsiveness*/
@media (max-width:1799px) {
    .package-card .packageH3 {font-size: 35px;line-height: 39px;}
}
@media (max-width:1699px) {
    .package-card .packageH3{ margin-bottom: 20px;}
    .package-card .packageTitle{ font-size: 14px; line-height: 19px;}
    .h3Sub{ font-size: 18px; line-height: 24px;  margin-left: 3px;}
    .package-ul li p{ font-size: 18px; line-height: 24px; margin-left: 13px;}
    .package-card button {line-height: 20px;font-size: 16px;padding: 15px 35px !important;}
}
@media (max-width:1520px) {
    .package-card button  {border-radius: 5px;}
    .package-card button {line-height: 18px;font-size: 14px;padding: 14px 25px !important;}
}
@media(max-width:1599px){
    .package-card .packageH3{font-size: 27px; line-height: 30px;}
}
@media (max-width:1399px) {
    .package-card{ border-radius: 15px; padding:30px 30px 25px 30px;} 
    .packageCardHeader div:not(:last-child){margin-right: 15px;}
    .package-card .packageH3{ font-size: 33px; line-height: 49px; margin-bottom: 15px;}
    .package-card .packageTitle{ font-size: 10px; line-height: 14px;}
    .h3Sub{ font-size: 16px; line-height: 20px;  margin-left: 3px;}
    .package-ul li p{ font-size: 14px; line-height: 18px; margin-left: 10px;}
    .package-ul li::before { width: 4px;}
}
@media (max-width:991px) {
    .package-card .packageH3 {font-size: 25px;line-height: 41px;}
}
@media (max-width:767px) {
    .package-card button {padding: 12px 25px !important;}
}
@media (max-width:575px) {
    .formStyle {padding: 28px 12px;}
    .package-card {padding: 30px 15px 25px 15px;}
    .packages-sec .formHeader {flex-direction: column;}
}
@media (max-width: 479px) {
    .package-card button {padding: 11px 25px !important;}
}