.fullpage-loader-holder {position: fixed;left: 0;top: 0;right: 0;bottom: 0;z-index: 99999;background: rgba(242, 243, 248, 0.95);display: flex;align-items: center;justify-content: center;}
.fullpage-loader-holder h1 {position: relative;color: #5a5a5a;font-size: 4em;line-height: initial;}
.fullpage-loader-holder h1:before {content: attr(data-text);position: absolute;overflow: hidden;max-width: 7em;white-space: nowrap;color: #E37229;animation: loading 4s linear infinite;}
@keyframes loading {
    0% {max-width: 0;}
}
/*media quries*/
@media (max-width: 575px){
    .fullpage-loader-holder h1 {font-size: 3em;}
}
@media (max-width: 319px){
    .fullpage-loader-holder h1 {font-size: 2em;}
}