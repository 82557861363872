.my-videos-sec {padding-bottom: 100px;}
.my-videos-sec .videos-table {min-width: 2100px;}
.my-videos-sec .videos-table .date-div { white-space: nowrap;}
.progress-bar{width: 75%;}
.videos-table .videos-table-th1{min-width: 500px; display: flex; align-items: center;}
.videos-table .videos-table-th1 p{font-size: 17px; line-height: 20px; color: #FD6F21;}
.videos-table .videos-table-th2{min-width: 130px;}
.videos-table .videos-table-th3{min-width: 160px;}
.videos-table .videos-table-th4{min-width: 170px;}
.videos-table .videos-table-th5{ min-width: 210px;}
.videos-table .videos-table-th6{min-width: 190px;}
.videos-table .videos-table-th7{ min-width: 150px;}
.videos-table .videos-table-th8{ min-width: 150px;}
.videos-table .videos-table-th9{ min-width: 160px;}
@media (max-width:575px) {
    .my-videos-sec .orange-btn {height: auto;margin: 20px auto 0 !important;}
}