.header .comunity-search-bar .form-control{ margin-bottom: 0;}
.header .comunity-search-bar .form-control::placeholder{color: #000;}
.ask-question-modal .modal-header{padding-bottom: 20px;}
.ask-question-modal .modal-body{padding: 30px 0px;}
.community-ask-question-form textarea{resize: none; min-height: 200px; overflow-y: auto;}
.ask-question-modal .modal-footer button{padding: 8px 15px; margin:0 10px 0 0; border-radius: 10px; width: auto; min-width: auto; max-width: none; font-size: 15px; line-height: 18px; font-weight: 600;}
.ask-question-modal .modal-footer button:last-child{margin: 0 0;}
.ask-question-modal-wrapper{display: flex; justify-content: flex-end;padding: 10px 0 0 0;}
.community-header-wrapper{border-bottom: 1px solid #efebeb;}
/* content right to left  */
.rtl .ask-question-modal .modal-footer button{margin: 0 0 0 10px;}
