.header {background: #fff;position: relative;z-index: 9999;}
.header.loggedin, .scrolled .header {left: 0;position: fixed;right: 0;top: 0;transition: all .8s ease-in-out;z-index: 9999;}
.header .navbar {padding: 13px 0 12px;}
.header .wrapHeaderBar {position: relative;width: 100%;}
.header .nav-flex {align-items: center;display: flex;justify-content: space-between;width: 100%;}
.header .navbar-brand {margin: 0 150px 0 0;padding: 0;width: 140px;}
.header .navBtns {align-items: center;border-radius: 10px;cursor: pointer;display: flex;justify-content: center;}
.header .search-form {background: #f2f3f8;border: 1px solid #dbdbdb;border-radius: 7px;display: flex;height: 46px;padding: 4px 13px;position: relative;width: 730px;}
.header .search-form .search-icon {color: #e37229;font-size: 16px;line-height: 16px;margin: 0 15px 0 0;cursor: pointer;}
.header .search-form input {background: none;border: none;color: #000;font-size: 16px;font-weight: 700;line-height: 20px;opacity: .62;}
.header .search-form input:focus {border: none;box-shadow: none;outline: none;}
.header .user-links-block {transform: scale(1);transform-origin: 100% 50%;transition: all .3s ease-in-out;}
.header .nav-right-bar div:not(:last-child) {margin-right: 8px;}
.header .header-user-btn {background: #e37229;background: linear-gradient(180deg,#e37229,#505050);border-radius: 50%;color: #fff;height: 36px;transition: all .3s ease-in-out;width: 36px; cursor: pointer;}
.header .navbar-toggler {display: block;min-height: auto;border: 0 solid #0000;border-radius: 0;font-size: 0;line-height: 0;padding: 0;}
.header .faBar {align-items: center;display: flex;flex-direction: column;height: 100%;justify-content: center;position: relative;width: 100%;}
.header .faBar span {background-color: #fff;display: block;height: 2px;margin: 2px 0;width: 13px;}
.header .faCross span {bottom: 0;left: 0;margin: auto;position: absolute;right: 0;top: 0;}
.header .faBar span, .header .faCross span {transition: all .4s ease-in-out;}
.header .faCross span:first-child {transform: rotate(-45deg);}
.header .faCross span:nth-child(2) {display: none;visibility: hidden;}
.header .faCross span:nth-child(3) {transform: rotate(45deg);}
.header .navbar-toggler:focus {box-shadow: none;}
.header .navbar-collapse {position: absolute;right: 95px;top: 50%;transform: scale(1) translateY(-50%);transform-origin: 100% 50%;transition: all .3s ease-in-out;}
.header .collapse:not(.show) {position: absolute;transform: scale(0);}
.header .user-links-block.hide {transform: scale(0);}
.header .navbar-nav .nav-link {border-radius: 10px;color: #e37229;font-size: 16px;font-weight: 500;line-height: 20px;margin: 0 0 0 40px;padding: 0;text-transform: capitalize;text-transform: uppercase;transition: all .4s ease-in-out;}
.header .search-results {width: 100%;position: absolute;top: calc(100% + 12px);left: 0; z-index: 1000;}
.header .search-results .search-img {width: 100%;height: 100px;}
.header .search-results .search-img .videoImg {width: 100%;height: 100%;object-fit: cover;}
.header .search-results .search-popup-close-btn{padding: 0; position: absolute; z-index: 1000; top: -10px; right: -10px; border-radius: 50%; background: transparent; border: none; transition: all 0.3s ease-in-out; transform: rotate(360deg);}
.header .search-results .search-popup-close-btn:hover{ transform: rotate(-360deg);}
.header .upload-btn{background: linear-gradient(180deg, rgba(227,114,41,1) 0%, rgba(80,80,80,1) 100%); border: none; box-shadow: none; outline: none;}
.header .upload-btn:hover{background: linear-gradient(0deg, rgba(227,114,41,1) 0%, rgba(80,80,80,1) 100%);}
.header .nav-right-bar .dropdown-menu.show {left: auto;right: 0;}
.header .dropdown-item.active, .header .dropdown-item:active {background-color: #fd6f21;color: #fff;}
.header .dropdown-item.active p, .header .dropdown-item:active p {color: #fff;}
.active-user .navbar-collapse {right: 150px;}
.Community-btn{padding: 6px 12px; color: #fff; border-radius: 6px; font-size: 16px; line-height: 24px; font-weight: 400; margin-left: 10px;}
.header .heaader-list-items{ background: #FD6F21; color: #fff; border: none ;}
/* content right to left */
.rtl .header .search-form{flex-direction: row-reverse;}
.rtl .header .search-form .search-icon{margin: 0 0 0 15px;}
.rtl .header .search-form input{text-align: right;}
.rtl .go-tp-profile .dropdown-item p,.rtl .go-tp-profile .logout-dropdown span{display: flex; flex-direction: row-reverse; width: 100%; align-items: center;}
.rtl .header .heaader-list-items{text-align: right;}
.rtl .search-result-items .list-group-item a .list-group-item{text-align: right;}
/* MEDIA QURIES */
@media (max-width:1860px){
	.header .search-form{width:600px;}
}
@media(max-width:1722px){
	.header .navbar-expand-lg .navbar-nav .nav-link{margin: 0 0 0 15px;}
}
@media (max-width:1699px) {
	.header .navbar-brand{width: 180px;margin:0 70px 0 0;}
	.header .navBtns{ width: 40px; height: 40px; border-radius: 8px;}
	.header .navbar-collapse{ right: 115px; }
	.active-user .navbar-collapse {right: 140px;}
	.header .nav-right-bar div:not(:last-child){margin-right:8px;}
	.header .navBtns svg{font-size: 14px;}
	.header .navbar-expand-lg .navbar-nav .nav-link {padding: 15px 0px;}
	.rtl .navbar-brand{margin: 0 0 0 70px; }
	.header .openSearch input {height: 40px;}
	.header .openSearch {right: 105px;}
	.header .sidebar-video-wrapper {padding: 20px 30px;}
	.header .navbar-expand-lg .navbar-nav .nav-link { padding: 8px 0px; font-size: 14px; line-height: 20px;}
	.active-user .openSearch{right: 200px;}
}
@media (max-width:1439px){
	.header .search-form{width:550px;}
	.navbar-brand{width: 150px;margin:0 50px 0 0;}
	.rtl .navbar-brand{margin: 0 0 0 50px; }
}
@media (max-width:1399px) {
	.header .navBtns{ width: 35px; height: 35px; border-radius: 5px;}
	.header .nav-right-bar div:not(:last-child){margin-right:7px;}
	.header .searchStyle, .header .openSearch{height: 45px;}
	.header .sidebar-video-wrapper {padding: 20px;}
	.header .openSearch {right: 93px;}
	.header .openSearch input {height: 35px;}
	.header .navbar-collapse {right: 97px;}
	.active-user .navbar-collapse {right: 135px;}
	.header .search-form .input-holder input::placeholder{ font-size: 13px; line-height: 16px;}
	.header .search-form{width:325px;}
}
@media (max-width:1200px){
	.header .navbar-brand{width:120px;}
}
@media(max-width:1199px){
	.header .search-form{position: absolute;width:100%;left:0;top:calc(100% + 15px);background: #fff;z-index:2;-o-transform: scale(0);-ms-transform: scale(0);-moz-transform: scale(0);-webkit-transform: scale(0);transform: scale(0);transform-origin:50% 50%;transition: all ease-in-out 0.3s;}
	.header .search-form.show{-o-transform: scale(1);-ms-transform: scale(1);-moz-transform: scale(1);-webkit-transform: scale(1);transform: scale(1);}
}
@media(max-width:1100px){
	.header .navbar-expand-lg .navbar-nav .nav-link { margin: 0 0 0 8px; font-size: 12px;line-height: 19px;}
}
@media(max-width:1000px){
	.navbar-brand { width: 120px; margin: 0 25px 0 0;}
	.header .search-form { width: 354px;}
	.rtl .navbar-brand{margin: 0 0 0 25px; }
}
@media (max-width:991px) {
	.header .openSearch{width: 400px;}
	.header .navbar-nav{flex-direction: column; justify-content: flex-start; align-items: center; padding-left: 0px;margin-top: 12px;}
	.header .navbar-nav{display:block;}
	.header .navbar-collapse {width: 100%;top:100%;-o-transform: translateY(0);-ms-transform: translateY(0);-moz-transform: translateY(0);-webkit-transform: translateY(0);transform: translateY(0);left:0;right:0;z-index:3;}
	.header .navbar-expand-lg .navbar-nav .nav-link:first-child{border-top:1px solid #E37229;}
	.header .navbar-expand-lg .navbar-nav .nav-link{padding:10px 10px;background:#E37229;margin:0;display:block;border-radius: 0;color:#fff;border-bottom:1px solid #fff;transition: all ease-in-out 0.3s;}
	.header .navbar-expand-lg .navbar-nav .nav-link:hover{background:#fff;color:#E37229}
	.header .openSearch input {height: 100%;}
	.header.active-user .openSearch{top: calc(100% + 20px);}
	.header .search-result-items {background: #151721;}
	.header .search-form { width: 100%;}
}
@media (max-width:767px) {
	.header .navbar-collapse{ padding: 0;}
	.header .searchStyle, .header .openSearch{width: 350px;}
	.header .search-results{top: 125px;}
	.header .search-results .search-img{height: 80px;}
	.header .header-btn{width:36px;height:36px;}
	.header .navbar-brand {width: 130px;}
}
@media (max-width: 575px) {
	.header .searchStyle, .header .openSearch{width: calc(100% - 100px)}
	.header .sidebar-video-wrapper {padding: 20px 15px;}
	.header .search-results.non-active-user{width: calc(100% - 100px);}
	.header .search-results .search-popup-close-btn{top: -12px; right: 0px;}
} 
@media (max-width: 479px) {	
	.header .search-results{padding: 0 25px;}
	.header .openSearch input {padding: 10px 35px 10px 10px;}
	.header .navbar-brand {width: 100px;}
	.header .header-user-btn{width:30px;height:30px;}
	.header .nav-right-bar div:not(:last-child){margin-right:5px;}
} 
@media (max-width: 319px) {
	.navbar-brand{margin: auto auto 10px auto; text-align: center;}
	.rtl .navbar-brand{margin: auto auto auto 10px; }
}
@media(max-width:290px){
	.header .header-user-btn { width: 22px; height: 22px; font-size: 13px; line-height: 16px;}
}