.banner{position: relative; overflow: hidden;padding:0;color:#fff;height:calc(100vh - 80px);}
.banner:after{width:100%;height:100%;content:"";background: rgb(0,0,0);background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(80,80,80,0) 100%);position:absolute;left:0;bottom:0;z-index:1;}
.banner .banner-content-holder{z-index: 2;left:0;bottom:130px;}
.overlay{position: absolute; background: transparent linear-gradient(90deg, #0D0F17 0%, #0D0F17FB 12%, #0D0F1700 100%); bottom: 0; right: 0; width: 55%; top: 0; z-index: 99;}
.mianBanner{ position: relative; z-index: 999;}
.player-wrapper{width:100%;height:100%;position: absolute; right: 0;top: 0;}
.player-wrapper video{height: 100%;width: 100%;object-fit: cover;object-position: center center;}
.player-wrapper .video-react-control-bar, .player-wrapper button{display: none;}
.player-wrapper >div {padding-top: 0px !important;}
.banner .customBox, .banner .customBox .row{height: 100%;}
.banner h1{margin:0 0 20px;}
.banner .wrapBanner{font-size:24px;line-height:36px;}
.banner .sofia-p{font-weight: 300; opacity: 0.6;}
.banner .wrapBannerBtn{padding:8px 0 0;}
.banner .wrapBannerBtn button{margin:0 10px}
.banner .orange-main-button{margin:0;}
@media (max-width:1899px){
	.banner .banner-content-holder{bottom:100px;}
}
@media (max-width:1399px) {
	.player-wrapper{width: 100%;}
	.overlay{width: 100%;}
	.banner .banner-content-holder{bottom:100px;}
	.banner .wrapBanner{font-size:20px;line-height:26px;}
}
@media (max-width:1199px) {
	.banner .wrapBanner{font-size:18px;line-height:26px;}
	.wrapBanner h1 {font-size: 38px;line-height: 42px;}
}
@media (max-width:991px) {
	.banner .banner-content-holder{bottom:20px;}
}
@media (max-width: 767px) {
	.banner{height:calc(100vh - 61px)};
	.wrapBanner {max-width: 100%;}
	.wrapBanner h1 {font-size: 33px;line-height: 39px;}
}
@media (max-width:575px) {
	.banner{padding:0;}
	.wrapBanner h1{font-size:22px;line-height:30px;margin:0 0 10px;}
	.banner .wrapBanner{font-size:14px;line-height:20px;}
	.banner .wrapBannerBtn button {padding:12px;height:44px;}
}
@media (max-width: 479px) {
	.wrapBannerBtn{flex-wrap: wrap;}
	.banner .banner-content-holder{bottom:0px;}
	.wrapBannerBtn div{margin-bottom: 10px;}
}   
@media (max-width: 319px) {
	.banner{  height: 100%;}
}   