.channel-sec .package-card {padding: 30px;text-align: left;background: #fff 0% 0% no-repeat padding-box;border-radius: 30px;}
.channel-sec .package-card .thumbnailImg {border-radius: 20px;overflow: hidden;margin: 0 0 15px;}
.channel-sec .package-card .thumbnailImg img {width: 100%;height: 100%;object-fit: cover;}
.channel-sec .channel-package-main {display: flex;align-items: center;flex-wrap: wrap;margin: 0px -1%;}
.channel-sec .channel-package-main .channel-package {width: 23%;margin: 0px 1% 40px;}
.channel-sec .package-card button {max-height: 72px;}
.channel-sec .channel-stats li{font-size:16px;line-height:19px;}
/* responsive */
@media only screen and (max-width:1599px){
    .channel-sec .channel-package-main .channel-package {width: 31.33%;}
}
@media only screen and (max-width:1399px){
    .channel-sec .package-card {padding: 40px 30px;}
}
@media only screen and (max-width:1199px){
    .channel-sec .channel-package-main .channel-package {width: 48%;}
}
@media only screen and (max-width:991px){
    .channel-sec .package-card {padding: 40px 20px;}
}
@media only screen and (max-width:767px){
    .channel-sec .channel-package-main {margin: 0px 0%;}
    .channel-sec .channel-package-main .channel-package {width: 100%;margin: 0px 0% 30px;}
}