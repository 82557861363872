/* .informative-links{padding-top: 50px;} */
.informative-links h1 .theme{margin-bottom: 30px; display: inline-block;}
.informative-links .informative-ul{list-style: none; display: flex; align-items: center; flex-wrap: wrap; padding: 0; margin: 0;}
.informative-links .informative-ul li{padding: 0px 15px; position: relative; width: 50%; margin-bottom: 20px;}
.informative-sec .works-custom-heading h3::after {position: absolute;content: "";width: 90px;height: 5px;background-image: linear-gradient(to right, #fd6f21, #dd6a2c);bottom: -8px;left: 0%;}
.informative-links .informative-ul li a {background-color: #1b1d27;border-radius: 12px;box-shadow: 0 5px 50px #06369f1a;cursor: pointer;margin-bottom: 20px;padding: 24px 30px;transition: all .3s ease-in-out;display: block;  font-size: 20px; line-height: 24px; font-weight: 400; text-decoration: none;color: #fff;}
.informative-links .informative-ul li a:hover {background-color: #fd6f21;color: #fff;}
.informative-links .no-pages {display: block;padding: 50px 0px;text-align: center;border-radius: 10px;width: 100%;background: #E37229;color: #fff;}
/*media queries*/
@media (max-width: 575px) {
  .informative-links .informative-ul li{width: 100%;}
  .informative-links .informative-ul li a{font-size: 16px; line-height: 20px;}
}