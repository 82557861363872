.mobileMemberImg{width: 50px; height: 50px; border-radius: 50%;margin: 20px auto;}
.mobileMemberImg img, .memberImg.mobileMemberImg img{width:100%; height:100%; max-width: 100%; object-fit: cover;}
@media (max-width:992px) {
.mobileMemberImg{margin: auto;}
 }





