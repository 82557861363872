.video-detail-section {display: flex;justify-content: space-between;}
.video-detail-section .video-area {-ms-overflow-style: none;max-height: 100%;min-width: 960px;overflow-x: hidden;overflow-y: auto;scrollbar-width: none;width: 66%;}
.video-detail-section .video-content {-ms-overflow-style: none;max-height: 100%;min-width: 512px;overflow-x: hidden;overflow-y: auto;padding: 0 20px 20px;scrollbar-width: none;width: 33%;}
.v-custom-player {height: 540px;margin: 0 0 30px;overflow: hidden;position: relative;}
.video-js {background: none;max-height: 50vh;width: 100%;}
.v-custom-player video {border-radius: 20px;}
.clicked-object-detail {background: #fff;border-radius: 10px;box-shadow: 0 0 10px #0000004d;display: none;font-size: 16px;line-height: 20px;padding: 20px;position: fixed;right: 20px;top: 20px;width: 250px;z-index: 99999;}
.clicked-object-detail .object-name {color: #fd6f21;font-size: 22px;font-weight: 700;line-height: 26px;margin: 0 0 10px;}
.clicked-object-detail h3 {color: #000;font-size: 16px;font-weight: 600;line-height: 20px;margin: 0;}
.clicked-object-detail .rating {color: #e1e1e1;font-size: 10px;line-height: 10px;}
.clicked-object-detail .link-to {color: #fd6f21;font-size: 12px;line-height: 15px;transform: translateX(0);transition: all .3s ease-in-out;}
.rating .rated {color: #fd6f21;}
.video-site-content h2 {color: #505050;font-size: 32px;font-weight: 700;line-height: 48px;margin-bottom: 15px;}
.v-user-details {margin-bottom: 30px;padding: 20px 0;}
.v-user-details .img {height: 62px;width: 62px;border-radius: 50%;overflow: hidden;}
.v-user-details .img img {height: 100%;width: 100%;object-fit: cover;}
.video-site-content .user-name {color: #505050;font-size: 18px;font-weight: 500;line-height: 22px;}
.video-site-content .publish-date {color: #505050;font-size: 14px;font-weight: 400;line-height: 17px;opacity: .6;}
.like-dislike-btn {background-color: #fd6e211a;border-radius: 30px;margin-right: 20px;overflow: hidden;}
.like-dislike-btn button, 
.make-video-favorite button, 
.video-site-content .share-button {background-color: initial;border-color: #0000;border-width: 0;color: #fd6e21;font-size: 14px;font-weight: 500;line-height: 17px;overflow: hidden;padding: 8px 15px;}
.like-dislike-btn button {border-radius: 0;min-width: 58px;position: relative;}
.like-dislike-btn button:first-child:before {background-color: #fd6e2166;content: "";height: 100%;position: absolute;right: 0;top: 0;width: 1px;}
.video-site-content .share-button {background-color: #fd6e211a;border-radius: 30px;margin-right: 20px;}
.make-video-favorite button {background-color: #fd6e211a;border-radius: 30px;color: #fd6f21;}
.exploreCard .make-video-favorite .count, 
.video-site-content .make-video-favorite .count {color: red;font-size: 20px;font-weight: 500;line-height: 24px;margin: 0 0 0 5px;}
.v-user-details .make-video-favorite .count {font-size: 14px;font-weight: 500;line-height: 17px;}
.video-site-content .v-description {background: #fff;border-radius: 10px;box-shadow: 0 0 14px #0000001a;margin-bottom: 50px;padding: 30px 33px;}
.v-description p {color: #fd6f21;font-weight: 500;opacity: 1;position: relative;}
.v-description p:after {background-color: #fd6f21;bottom: 0px;content: "";height: 2px;left: 0;position: absolute;width: 30px;}
.v-description span {display: block;font-size: 14px;font-weight: 400;line-height: 24px;margin-bottom: 20px;}
.v-description .link.orangeLinkBtn {display: inline-block;font-size: inherit;margin-left: 7px;min-height: auto;min-width: auto;}
.related-blogs {background: #fff;border-radius: 10px;box-shadow: 0 0 14px #0000001a;padding: 30px 33px;}
.related-blogs h4 {color: #fd6f21;font-size: 16px;font-weight: 500;line-height: 20px;margin-bottom: 27px;position: relative;}
.related-blogs h4:before {background-color: #fd6f21;bottom: 0;content: "";height: 2px;left: 0;position: absolute;width: 30px;}
.related-blogs .main-comment-wrapper {margin-bottom: 30px;}
.related-blogs .userImg {box-shadow: 0 0 10px #fd6f21cc;overflow: hidden;}
.related-blogs .text-area-wrapper {flex: 1 1;margin-left: 20px;position: relative;width: 100%;}
.text-area-wrapper textarea {background-color: initial;border: none;border-bottom: 1px solid #c4c1c1;border-radius: 0;max-height: 40px;padding-left: 0;resize: none;}
.related-blogs .user-comment-wrapper {width: calc(100% - 68px);margin-left: 20px;}
.comment-user-name {color: #101010;font-size: 14px;font-weight: 500;line-height: 17px;}
.comment-time {color: #a2a2a2;font-size: 14px;font-weight: 300;line-height: 17px;margin-left: 27px;}
.related-blogs .show-btns {background-color: initial;border: none;color: #fd6f21;font-size: 13px;line-height: 17px;outline: none;padding: 0;}
span.comment {text-align: justify;display: inline-block;word-break: break-word;}
.recomemded-videos .section-heading {color: #000;font-size: 24px;font-weight: 700;line-height: 28px;margin: 0 0 40px;}
.v-custom-sitecards .exploreCard {margin-bottom: 40px;}
.v-custom-sitecards .exploreImg {height: 136px;margin: 0 30px 20px 0;width: 136px;}
.v-custom-sitecards .cardFooter{width: 100%}
.v-custom-sitecards .exploreImg a {height: 100%;width: 100%;}
.v-custom-sitecards .exploreImg img {width: 100%;height: 100%;object-fit: cover;border-radius: 20px;}
/*used more*/
.like-dislike-btn button, .make-video-favorite button, .video-site-content .share-button {background-color: initial;border-color: #0000;border-width: 0;color: #fd6e21;font-size: 14px;font-weight: 500;line-height: 17px;overflow: hidden;padding: 8px 15px;background-color: #fd6e211a;}
.player-icon {display: none;font-size: 50px;}
.exploreCard:hover .player-icon {align-items: center;display: flex;height: 100%;justify-content: center;left: 0;position: absolute;top: 0;width: 100%;}
.player-icon svg {fill: #fff;}
.exploreCard:hover .video-img {opacity: .5;}
.exploreCard .cardFooter {align-items: flex-start;display: flex;justify-content: space-between;padding-top: 15px;}
.exploreCard .cardFooter .videoReview {display: flex;flex: 1 1;flex-direction: column;}
.exploreCard .cardFooter p {-webkit-box-orient: vertical;-webkit-line-clamp: 2;display: -webkit-box;font-weight: 400;margin-bottom: 5px;max-width: 100%;overflow: hidden;text-overflow: ellipsis;}
.exploreCard .cardFooter span {font-family: outfit;font-size: 14px;font-weight: 400;line-height: 18px;opacity: .4;}
.exploreCard .make-video-favorite button {align-items: center;border: none;color: red;display: flex;font-size: 24px;background-color: #fd6e211a;border-radius: 30px;}
.exploreCard .make-video-favorite .count, .video-site-content .make-video-favorite .count {color: red;font-size: 20px;font-weight: 500;line-height: 24px;margin: 0 0 0 5px;}
.v-user-details .make-video-favorite .count {font-size: 14px;font-weight: 500;line-height: 17px;}
/* content right to left  */
.rtl .v-player-container .video-detail-section{flex-direction: row-reverse;}
.rtl .recomemded-videos .section-heading{text-align: right;}
.rtl .v-custom-sitecards .exploreCard{flex-direction: row-reverse;}
.rtl .v-custom-sitecards .exploreImg{margin: 0 0px 20px 30px;}
.rtl .userImg-title{flex-direction: row-reverse;}
.rtl .userImg.me-3{margin-left: 10px;}
.rtl .exploreCard .cardFooter span{text-align: right;}
/*responsiveness*/
@media (max-width: 1899.98px){
    .v-player-container .video-detail-section .video-content, 
    .v-player-container .video-detail-section .video-area {min-width: auto;}
    .v-player-container .v-custom-player {height: 500px;}
    .right.dashboard-sitePages .v-custom-player{height: 565px;}
    .sitePages-width .v-custom-player{height: 565px;}
}
@media (max-width: 1799.98px) {
    .v-player-container {height: calc(100vh - 56px);}
    .v-custom-player {height: 450px;}
    .right.dashboard-sitePages .v-custom-player{height: 510px;}
    .sitePages-width .v-custom-player{height: 510px;}
}
@media (max-width: 1599.98px){
    .video-site-content h2 {font-size: 33px;font-weight: 500;}
    .v-description span {font-size: 14px;line-height: 20px;}
    .v-custom-player {height: 378px;}
    .right.dashboard-sitePages .v-custom-player{height: 440px;}
    .sitePages-width .v-custom-player{height: 440px;}
    .v-custom-sitecards .exploreImg{width:100px;height:100px;margin:0 20px 0 0;}
    .v-custom-sitecards .cardFooter {width: calc(100% - 120px);}
}
@media (max-width: 1399.98px){
    .video-site-content h2{font-size: 30px;line-height: 35px;}
    .v-player-container .video-detail-section .video-content {width: 46%;}
    .v-player-container .video-detail-section .video-area {width: 50%;}
    .v-description span {font-size: 12px;line-height: 16px;}
    .v-custom-player {height: 250px;}
    .v-player-container {height: calc(100vh - 43px);}
    .right.dashboard-sitePages .v-custom-player{height: 300px;}
    .sitePages-width .v-custom-player{height: 300px;}
    .v-custom-sitecards .exploreImg img{height:100%}
}
@media (max-width: 1199.98px) {
    .v-custom-player {height: 200px;}
    .right.dashboard-sitePages .v-custom-player{height: 240px;}
    .sitePages-width .v-custom-player{height: 240px;}
    .v-player-container .v-description {margin: 0 0 50px;}
    .v-player-container .video-detail-section {flex-direction: column-reverse;}
    .v-player-container .video-detail-section .video-content, .v-player-container .video-detail-section .video-area {width: 100%;}
    .v-player-container .video-detail-section .video-content {padding-bottom: 10px;max-height: initial;overflow-x: hidden;overflow-y: hidden;padding:0 20px 20px;;}
    .v-player-container .video-detail-section,  .v-player-container .container-fluid {height: auto !important;}
    .v-user-details {padding: 10px 0;margin-bottom: 20px;}
    .v-player-container .v-user-details .img {width: 50px;height: 50px;}
    .video-site-content .user-name {font-size: 16px;line-height: 20px;}
    .video-site-content .publish-date {font-size: 12px;line-height: 16px;}
    .like-dislike-btn button,.video-site-content .share-button, .make-video-favorite button,.video-site-content .make-video-favorite .count{font-size: 13px; line-height: 16px;}
    .like-dislike-btn ,.video-site-content .share-button{margin-right: 10px;}
    .v-custom-sitecards .exploreCard .make-video-favorite .count, .video-site-content .make-video-favorite .count{font-size: 13px; line-height: 16px;}
    .rtl .v-player-container .video-detail-section {flex-direction: column-reverse;}
}
@media (max-width: 991.98px){
   .v-custom-player {height: 290px;}
    .right.dashboard-sitePages .v-custom-player{height: 350px;}
    .v-player-container{height: 100%;}
    .v-player-container .video-detail-section {flex-direction: column;}
    .v-player-container .video-detail-section .video-content, .v-player-container .video-detail-section .video-area {width: 100%;}
    .v-player-container .video-detail-section .video-content {padding-bottom: 10px;max-height: initial;overflow-x: hidden;overflow-y: hidden;padding:0 20px 20px;;}
    .v-player-container .video-detail-section .video-area {max-height: 500px;}
    .sitePages-main-width .v-custom-player{height: 415px;}
    .v-player-container .video-detail-section .video-content{padding-top: 20px ;}
    .video-js{max-height: 100%;}
    .rtl .v-player-container .video-detail-section {flex-direction: column;}

}
@media (max-width: 767.98px){
    .video-site-content h2 {font-size: 26px;line-height: 28px;}
    .v-custom-player {height: 180px;}
    .right.dashboard-sitePages .v-custom-player{height: 240px;}
     .sitePages-main-width .v-custom-player{height: 300px;}
}
@media (max-width: 575.98px){
    .video-site-content h2 {font-size: 22px;line-height: 25px;text-align: left;}
    .v-custom-player {height: 250px;}
    .sitePages-main-width .v-custom-player{height: 250px;}
}
@media (max-width: 479.98px) {
   .v-custom-player {height: 165px;}
   .sitePages-main-width .v-custom-player{height: 165px;}
   .v-player-container .v-custom-sitecards .exploreCard {flex-direction: column;}
   .v-player-container .video-detail-section .video-content {padding: 0 12px 20px;}
}
@media(max-width:385px){
    .like-dislike-btn button, .video-site-content .share-button, .make-video-favorite button{padding: 6px 9px;}
    .like-dislike-btn, .video-site-content .share-button {margin-right: 2px;}
}