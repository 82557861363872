.home-blogs-section .blog-image-holder{margin:0 0 40px;}
.home-blogs-section .blog-content-holder{font-size:24px;line-height:30px;color:#66707C;}
.home-blogs-section .sub-heading{font-size:40px;line-height:45px;color:#E37229;}
.home-blogs-section h3{margin:0 0 30px;}
.home-blogs-section h3 a{font-size:34px;line-height:38px;font-weight: 700;color:#000;transition: all ease-in-out 0.3s;}
.home-blogs-section h3 a:hover{color:hsl(24, 77%, 53%);}
.home-blogs-section .content-holder{margin:0 0 40px;}
@media(max-width:1520px){
    .home-blogs-section .sub-heading{font-size:30px;line-height:35px;}
}
@media(max-width:1399px){
    .home-blogs-section h3 a{font-size: 26px;line-height:30px;font-weight:600;}
}
@media(max-width:1199px){
    .home-blogs-section .blog-image-holder{margin:0 0 20px;}
    .home-blogs-section h3{margin:0 0 15px;}
    .home-blogs-section h3 a{font-size:24px;line-height: 27px;;}
    .home-blogs-section .blog-content-holder {font-size:18px;line-height:25px;}
}
@media(max-width:991px){
    .home-blogs-section .content-holder{margin:0 0 20px;}
}
@media(max-width:575px){
    .home-blogs-section .sub-heading{font-size:25px;line-height:30px;}
}