.works{position: relative;padding-bottom: 200px;padding-top: 200px;z-index: 2;}
.works .sectionTitle{position: relative; z-index: 999;}
.works .bar{position: absolute; top: 0px; right: 15%; left: 0;  width: 180px;height: 40%;background: #FC491E;transform: skew(-42deg); margin: auto; overflow: hidden; background: transparent linear-gradient(174deg, #FD6F21 0%, #0D0F17 100%) 0% 0% no-repeat padding-box;opacity: 0.65;}
.lineH2{position: relative;}
.wrapLine{ position: absolute; right: 0px; top: 25px; bottom: 0; margin-left: auto; height: 0;}
.line{display: block; width: 350px; border-bottom: 1px dashed #fff; height: 0px; opacity: 0.5; margin-right: 187px;}
.line1{content:''; display:block;width: 220px; border-bottom: 1px dashed #fff;height: 1px; transform: rotate(45deg); position: absolute; top: 75px; right: 0;opacity: 0.5;}
.lineH2 .circle{opacity: 1; opacity: 1; position: absolute; right: -10px; bottom: -15px; border: 1px solid #fff;}
.style360 {position: relative; width: 767px; margin: 0 auto; z-index: 99;}
.pieText h3{font-size: 20px; line-height: 25px; font-weight: 700; color: #fff;margin-bottom: 10px;}
.pieText .pie-P{font-size: 16px; line-height: 22px; font-weight: 400; margin-bottom: 0; opacity: 0.4;color:#fff;}
.shape-hover, .shape2, .shape3{position: relative;}
.shape-hover .backImg{ position: absolute; right: 0; left: 0; transition: all ease-in-out .4s;}
.shape1{column-gap: 30px;}
.wrap-style360img:hover{cursor: crosshair;}
.shape1, .shape2 .wrap-style360img:nth-child(1), .shape2 .wrap-style360img:nth-child(2), .shape3 .wrap-style360img:nth-child(1), .shape3 .wrap-style360img:nth-child(2){z-index: 9999;}
.shape2 .wrap-style360img:nth-child(1){position: absolute; top:-165px; left:-72px;}
.shape2 .wrap-style360img:nth-child(2){position: absolute; top:-165px; right:-72px;}
.shape3 .wrap-style360img:nth-child(1){position: absolute; top:155px; left:-60px;}
.shape3 .wrap-style360img:nth-child(2){position: absolute; top:155px; right:-60px;}
.shape4{margin-top: 248px;}
.pie-circle, .pie-circle1, .pie-circle2, .pie-circle3, .pie-circle4, .pie-circle5{ display: block; position: absolute; right: 0; left: 0; top: 0; bottom: 0; margin: auto; border-radius: 50%;}
.pie-circle1{ width: 80px; height: 80px; border:1px solid #FD6F21; opacity: 0.3;}
.pie-circle2{ width: 100px; height: 100px; border:1px solid #FD6F21; opacity: 0.2;}
.pie-circle3{ width: 120px; height: 120px; border:1px solid #fd6f2196; opacity: 0.2;}
.pie-circle4{ width: 130px; height: 130px; border-radius: 50%; border:1px solid #fd6f2196; opacity: 0.1;}
.pie-circle5{ width: 50px; height: 50px; background-color: #FD6F21; opacity: 0.9;}
.dark{ width: 300px; height: 300px; background-color: #0D0F17; border-radius: 50%; display: flex; align-items: center; justify-content: center; position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto;}
.display .bii{display: none;}
.display{position: relative;transition: all ease-in-out .3s;}
.hoverImg{  margin: 0; position: absolute; top: 100%; left: 100%; -ms-transform: translate(-50%, -53%); transform: translate(-50%, -50%);transition: all ease-in-out 0s;visibility: hidden; opacity: 0; z-index: -1;}
.wrap-style360img:hover .hoverImg{ margin: 0; position: absolute; top: 0; left: 40%; -ms-transform: translate(-50%, -53%); transform: translate(-50%, -50%);transition: all ease-in-out .4s;visibility: visible; opacity: 1;z-index: 1;}
.pieText{width: 210px;}
.pieText{position: absolute; top: 25%; left: 25%; margin: auto; bottom: 0;}
.wrap-style360img:hover .display .bii{display: block;}
.wrap-style360img:hover .hide{display: none;}
/* shape1 */
.shape1 .wrap-style360img:nth-child(1) .pieText{ top: 30%; left: 35%;}
.shape1 .wrap-style360img:nth-child(2) .pieText{ top: 30%; left: 15%;}
.shape1 .wrap-style360img:nth-child(1) .hoverImg{right: 0; bottom: 0;top:auto;left:auto;-o-transform: translate(0);-ms-transform: translate(0);-moz-transform: translate(0);-webkit-transform: translate(0);transform: translate(0);}
.shape1 .wrap-style360img:nth-child(1):hover .hoverImg{right:-77px; bottom:-155px;}
.shape1 .wrap-style360img:nth-child(2) .hoverImg{left:0;bottom: 9px;top:auto;right:auto;-o-transform: translate(0);-ms-transform: translate(0);-moz-transform: translate(0);-webkit-transform: translate(0);transform: translate(0);}
.shape1 .wrap-style360img:nth-child(2):hover .hoverImg{left:-90px; bottom:-161px;}
/* shape2 */
.shape2 .wrap-style360img:nth-child(1) .pieText{ top: 33%; left: 13%;}
.shape2 .wrap-style360img:nth-child(2) .pieText{ top: 40%;}
.shape2 .wrap-style360img:nth-child(1) .hoverImg { left:auto;right:0; top:auto; bottom: 0;}
.shape2 .wrap-style360img:nth-child(1):hover .hoverImg{right:-230px;bottom:-30px}

.shape2 .wrap-style360img:nth-child(2) .hoverImg{right:auto;left:0;bottom:0;top:auto;}
.shape2 .wrap-style360img:nth-child(2):hover .hoverImg { left:-130px;bottom:-66px;}
/* shape3 */
.shape3 .wrap-style360img:nth-child(1) .hoverImg{top:auto; left: 0;bottom:0;right:auto;-o-transform: translate(0);-ms-transform: translate(0);-moz-transform: translate(0);-webkit-transform: translate(0);transform: translate(0);} 
.shape3 .wrap-style360img:nth-child(1):hover .hoverImg{ left:calc(100% + 35px);bottom:100%;}
.shape3 .wrap-style360img:nth-child(2) .hoverImg{top:auto;left:auto;right:0;bottom:0;}
.shape3 .wrap-style360img:nth-child(2):hover .hoverImg {right:calc(100% + 22px);bottom:calc(100% - 14px);left:auto;top:auto;-o-transform: translate(0);-ms-transform: translate(0);-moz-transform: translate(0);-webkit-transform: translate(0);transform: translate(0);}
/* shape4 */
.shape4 .wrap-style360img .pieText{ top: 37%; left: 22%;}
.shape4 .wrap-style360img .hoverImg{top:auto;right:auto;bottom:0;left: 50%;-o-transform: translateX(-50%);-ms-transform: translateX(-50%);-moz-transform: translateX(-50%);-webkit-transform: translateX(-50%);transform: translateX(-50%);} 
.shape4 .wrap-style360img:hover .hoverImg{bottom:calc(100% + 88px);}
.works .mobile-works{display: none !important;}

/* MEDIA QUERY */
@media (max-width:1600px) {
	.works{padding:100px 0;}
	.line{max-width: 280px; margin-right: 152px;}
	.line1{max-width: 180px; top: 63px;}
}
@media (max-width: 1399px) {
	.works{padding-bottom: 150px;}
	.line{ max-width: 200px;margin-right: 113px;}
	.line1{ max-width: 130px; top: 45px; }
	.lineH2 .circle{ right: -15px; bottom: -9px;}
	.hoverImg {width: 100px;}
	.pieText { width: 140px; top: 19%; left: 29%;}
	.pieText h3{font-size: 17px; line-height: 22px; margin-bottom:3px;}
	.pieText .pie-P{font-size: 12px; line-height: 17px; }
	.pie-circle1{ width: 50px; height: 50px; }
	.pie-circle2{ width: 70px; height: 70px; }
	.pie-circle3{ width: 80px; height: 80px; }
	.pie-circle4{ width: 100px; height: 100px; }
	.pie-circle5{ width: 35px; height: 35px; }
	.dark{width: 150px; height:150px;}
	.shape1 { column-gap: 13px; margin-bottom: 13px;}
	.display .bii , .hide img {width: 240px;}
	.display img.bii2, .hide img.bii2{width: 270px;}
	.display img.bii3, .hide img.bii3{width: 275px;}
	.shape1 .wrap-style360img:nth-child(1):hover .hoverImg {right: -57px;bottom: -116px;}
	.shape1 .wrap-style360img:nth-child(2):hover .hoverImg {left: -56px;bottom: -114px;}
	.shape2 .wrap-style360img:nth-child(2):hover .hoverImg {left: -82px;bottom: -51px;}
	.shape2 .wrap-style360img:nth-child(1):hover .hoverImg { right:-180px;bottom:-55px;}
	.shape3 .wrap-style360img:nth-child(1):hover .hoverImg {left:100%;bottom: 89%;}
	.shape3 .wrap-style360img:nth-child(2):hover .hoverImg {right:calc(100% + 0px);bottom: calc(100% - 35px);}
	.shape4 .wrap-style360img:hover .hoverImg {bottom: calc(100% + 38px);}

	.shape2 .wrap-style360img:nth-child(1){top: -143px; left: 62px;}
	.shape2 .wrap-style360img:nth-child(2) { top: -143px; right: 62px;}
	.shape3 .wrap-style360img:nth-child(1){ top:81px; left:65px;}
	.shape3 .wrap-style360img:nth-child(2){ top:81px; right:65px;}
	.shape4 { margin-top: 150px;}
	.workReltive{padding-top: 10px;}
	.works .bar {width: 120px; right: 21%;}
	.labelImg{width: 100px;}
	
	.shape2 .wrap-style360img:nth-child(2) .hoverImg{width: 100px;}
	.works {padding:80px 0;}
	.shape2 .wrap-style360img:nth-child(2) .pieText {top: 36%;}
}
@media (max-width:900px) {
	.pieText{width: 110px;}
	.pieText h3{font-size: 12px; line-height: 14px; }
	.pieText .pie-P { font-size: 8px; line-height: 12px;}
	.shape1 .wrap-style360img:nth-child(1) .pieText { top: 25%; left: 32%;}
	.style360 {width: 80%;}
	.pie-circle1{ width: 35px; height: 35px; }
	.pie-circle2{ width: 45px; height: 45px; }
	.pie-circle3{ width: 50px; height: 50px; }
	.pie-circle4{ width: 55px; height: 55px; }
	.pie-circle5{ width: 25px; height: 25px; }
	.dark{width: 90px; height:90px;}
	.labelImg{width:120px;}
	.display .bii, .hide img {width: 160px;}
	.display img.bii2, .hide img.bii2 { width: 170px;}
	.display img.bii3, .hide img.bii3 {width: 180px;}
	.shape1 { column-gap: 9px; margin-bottom: 4px;}
	.shape2 .wrap-style360img:nth-child(1){top: -89px; left: 94px;}
	.shape2 .wrap-style360img:nth-child(2) { top: -89px; right: 94px;}
	.shape3 .wrap-style360img:nth-child(1){ top:63px; left:104px;}
	.shape3 .wrap-style360img:nth-child(2){ top:63px; right:104px;}
	.shape4 { margin-top: 103px;}
	.shape2 .wrap-style360img:nth-child(1) .hoverImg {top: -3px;}
	.shape2 .wrap-style360img:nth-child(1):hover .hoverImg { left: auto; top:auto;}
	.shape2 .wrap-style360img:nth-child(2) .hoverImg,
	.shape3 .wrap-style360img:nth-child(1) .hoverImg ,
	.shape3 .wrap-style360img:nth-child(2) .hoverImg {width: 100px;}
}
@media (max-width:992px) {
	.line{max-width: 150px;margin-right: 60px;}
	.line1{max-width: 70px;top: 25px;}
}
@media (max-width: 991px) {
	.pieText{width: 110px;}
	.pieText h3{font-size: 12px; line-height: 14px; }
	.pieText .pie-P { font-size: 8px; line-height: 12px;}
	.shape1 .wrap-style360img:nth-child(1) .pieText { top: 25%; left: 32%;}
	.style360 {width: 80%;}
	.pie-circle1{ width: 35px; height: 35px; }
	.pie-circle2{ width: 45px; height: 45px; }
	.pie-circle3{ width: 50px; height: 50px; }
	.pie-circle4{ width: 55px; height: 55px; }
	.pie-circle5{ width: 25px; height: 25px; }
	.dark{width: 90px; height:90px;}
	.hoverImg {width:90px;}
	.display .bii, .hide img {width: 160px;}
	.display img.bii2, .hide img.bii2 { width: 170px;}
	.display img.bii3, .hide img.bii3 {width: 180px;}
	.shape-hover, .shape2, .shape3{width:490px;margin:0 auto;}
	.shape1 { column-gap: 9px; margin-bottom: 6px;}
	.hoverImg,
	.shape2 .wrap-style360img:nth-child(2) .hoverImg, .shape3 .wrap-style360img:nth-child(1) .hoverImg, .shape3 .wrap-style360img:nth-child(2) .hoverImg{width:60px;}
	.shape1 .wrap-style360img:nth-child(1) .hoverImg { top: -2px;}
	.shape1 .wrap-style360img:nth-child(1):hover .hoverImg { right: -36px;bottom: -75px;top: auto;}
	.shape1 .wrap-style360img:nth-child(2):hover .hoverImg {left: -33px;bottom: -74px;}
	.shape2 .wrap-style360img:nth-child(1):hover .hoverImg {right: -110px;bottom: -27px;}
	.shape2 .wrap-style360img:nth-child(2):hover .hoverImg {left: -53px;bottom: -23px;}
	.shape3 .wrap-style360img:nth-child(1):hover .hoverImg {left: 102%;bottom: 95%;}
	.shape3 .wrap-style360img:nth-child(2):hover .hoverImg {right: calc(100% + 0px);bottom: calc(100% - 10px);}
	.shape4 .wrap-style360img:hover .hoverImg {bottom: calc(100% + 30px);}
	.shape2 .wrap-style360img:nth-child(1){top: -90px; left: 32px;}
	.shape2 .wrap-style360img:nth-child(2) { top: -90px; right: 33px;}
	.shape3 .wrap-style360img:nth-child(1){ top:64px; left:41px;}
	.shape3 .wrap-style360img:nth-child(2){ top:64px; right:43px;}
	.works{padding-bottom: 100px;}
	.shape4 { margin-top: 103px;}
	.pieText {left: 25%;}
	.shape1 .wrap-style360img:nth-child(2) .pieText {top: 25%;left: 10%;}
}
@media (max-width:700px) {
	.shape1 { column-gap: 6px; margin-bottom: 5px;}
	.shape2 .wrap-style360img:nth-child(1){top: -90px; left: 21px;}
	.shape2 .wrap-style360img:nth-child(2) { top: -91px; right: 47px;}
	.shape3 .wrap-style360img:nth-child(1){ top:59px; left:29px;}
	.shape3 .wrap-style360img:nth-child(2){ top:59px; right:57px;}
	.shape4 { margin-top: 99px;}
}
@media (max-width:767px) {
	.wrapLine {display: none;}
	.works { padding-bottom: 100px;}
}
@media (max-width:600px) {
	.shape1 {column-gap: 8px; margin-bottom: 7px;}
	.display .bii, .hide img { width: 155px;}
	.display img.bii3, .hide img.bii3 { width: 177px;}
	.shape2 .wrap-style360img:nth-child(1){top: -90px; left: -18px;}
	.shape2 .wrap-style360img:nth-child(2) { top: -89px; right: 92px;}
	.shape3 .wrap-style360img:nth-child(1){ top:54px; left:-12px;}
	.shape3 .wrap-style360img:nth-child(2){ top:56px; right:97px;}
	.shape4 { margin-top: 94px;}
}
@media (max-width:500px) {
	.shape1 .wrap-style360img:nth-child(1):hover .hoverImg, 
	.wrap-style360img:hover .display .bii {display: none !important;}
	.works .shapesAbs, .works .dark .pie-circle, .works .dark {display: none;}
	.works .mobile-works .pieText{position: unset;width: unset;}
	.works .mobile-works .pieText{padding: 12px;background-color: #1B1D27;margin-bottom: 10px;}
	.works .mobile-works .col{z-index: 99;}
	.works .mobile-works {display: unset !important;}
	.pieText h3{font-size: 17px; line-height: 22px; }
	.pieText .pie-P { font-size: 12px; line-height: 17px;}
	.works{padding:50px 0;}
}
@media (max-width:479px) {
	.shape1 { column-gap: 10px;}
	.display .bii, .hide img { width: 100px;}
	.display img.bii2, .hide img.bii2, .display img.bii3, .hide img.bii3 { width: 110px;}
	.shape2 .wrap-style360img:nth-child(1){top: -55px; left: 33px;}
	.shape2 .wrap-style360img:nth-child(2) { top: -55px; right: 33px;}
	.shape3 .wrap-style360img:nth-child(1){ top:45px; left:39px;}
	.shape3 .wrap-style360img:nth-child(2){ top:45px; right:39px;}
	.shape4 {margin-top: 74px;}
	.works {padding-bottom: 40px;}
}
@media (max-width:440px) {
	.shape1 { column-gap: 10px;}
	.display .bii, .hide img { width: 100px;}
	.display img.bii2, .hide img.bii2, .display img.bii3, .hide img.bii3 { width: 110px;}
	.shape2 .wrap-style360img:nth-child(1){top: -55px; left: 33px;}
	.shape2 .wrap-style360img:nth-child(2) { top: -55px; right: 33px;}
	.shape3 .wrap-style360img:nth-child(1){ top:45px; left:39px;}
	.shape3 .wrap-style360img:nth-child(2){ top:45px; right:39px;}
	.shape4 {margin-top: 74px;}
}