.information-sec {padding: 100px 0px;}
.information-sec h1 {margin-bottom: 30px;}
.information-sec .para {margin-bottom: 80px;font-size: 20px;font-weight: 400;color: rgba(225, 225, 225, 0.6);max-width: 1494px;}
.information-sec .wrapper {padding: 30px;background-color: #1b1d27;border-radius: 20px;}
.information-sec .wrapper p {font-size: 16px;font-weight: 400;color: rgba(225, 225, 225, 0.6);}
.information-sec .col-lg-6 {margin-bottom: 80px;}
.information-sec .details h4 {font-size: 36px;font-weight: 500;}
.information-sec .details p {margin-bottom: 60px;color: rgba(225, 225, 225, 0.6);font-size: 20px;font-weight: 500;}
.information-sec .details .para1 {margin-bottom: 30px;}
.information-sec .ul-list ul {list-style: none;margin-bottom: 0;}
.information-sec .ul-list {border-left: 2px solid #fa6400;}
.information-sec .ul-list ul li {font-size: 18px;font-weight: 500;}
.information-sec .ul-list {margin-bottom: 60px;}
.information-sec .cookie-policy {background: transparent linear-gradient(180deg, #fe6c30 0%, #fe6c30 100%) 0%  0% no-repeat padding-box;box-shadow: 0px 0px 16px #0000001a;border-radius: 20px;padding: 32px 48px 32px 48px;margin-bottom: 60px;display: flex;justify-content: space-between;max-height: 150px;}
.information-sec .cookie-policy p {font-size: 20px;font-weight: 400;color: #ffffff;}
@media (max-width: 1599px) {
  .information-sec .para,
  .information-sec .col-lg-6 {margin-bottom: 60px;}
}
@media (max-width: 1399px) {
  .information-sec {padding: 70px 0;}
  .information-sec h1 {margin-bottom: 20px;}
  .information-sec .para,
  .information-sec .col-lg-6,
  .information-sec .ul-list,
  .information-sec .cookie-policy {margin-bottom: 40px;}
  .information-sec .wrapper {padding: 20px;}
  .information-sec .wrapper .H2 {margin-bottom: 10px;}
  .information-sec .wrapper p {line-height: 26px;}
  .information-sec .details .para1 {margin-bottom: 20px;}
  .information-sec .details p {margin-bottom: 40px;}
  .information-sec .para,
  .information-sec .details p {font-size: 18px;}
  .information-sec .details h4 {font-size: 30px;}
}
@media (max-width: 575px) {
  .information-sec {padding: 30px 0;}
  .information-sec .para,
  .information-sec .col-lg-6,
  .information-sec .ul-list,
  .information-sec .cookie-policy {margin-bottom: 20px;}
  .information-sec .para,
  .information-sec .details p {font-size: 16px;line-height: 24px;}
  .information-sec .wrapper p {line-height: 24px;}
  .information-sec .details h4 {font-size: 26px;}
  .information-sec .ul-list ul {padding-left: 15px;}
  .information-sec .ul-list ul li {font-size: 16px;line-height: 24px;}
  .information-sec .details p {margin-bottom: 20px;}
  .information-sec .cookie-policy {padding: 20px 22px 20px 22px;}
}
@media (max-width: 467px) {
  .information-sec .cookie-policy {flex-direction: column;}
}