.footer{position: relative; padding: 70px 0 0;background: #505050;}
.wrapFooter .bar{position: absolute; bottom: 0px; right: 8%; width: 160px;height: 84.9%; transform: skew(40deg); overflow: hidden; background: transparent linear-gradient(174deg, #1f212f78 0%, #FD6F21 100%) 0% 0% no-repeat padding-box; z-index: 99; opacity: 0.65;}
.footerTop{text-align: center;}
.footerLogo{margin-bottom: 15px;}
.socialIcon{margin-bottom: 80px;}
.socialIcon ul, .footerLink ul{margin-left: 0px;margin-bottom:0; list-style: none; display: flex; justify-content: center; align-items: baseline;position: relative;}
.socialIcon ul li{margin:0 5px 10px}
.socialIcon ul li a{width: 50px; height: 50px;background:#fff;border-radius: 50%; display: flex; align-items: center; justify-content: center; transition: all ease-in-out .3s;} 
.socialIcon ul li a svg{font-size: 30px; transition: all ease-in-out .3s;}
.socialIcon ul li:hover a svg{color:#fff}
.socialIcon ul li.facebook a{color:#3b5998;}
.socialIcon ul li.facebook:hover a{background:#3b5998;}
.socialIcon ul li.twitter a{color:#1DA1F2;}
.socialIcon ul li.twitter:hover a{background:#1DA1F2;}
.socialIcon ul li.linkedin a{color:#0077B5;}
.socialIcon ul li.linkedin:hover a{background:#0077B5;}
.socialIcon ul li.pinterest a{color:#BD081C;}
.socialIcon ul li.pinterest:hover a{background:#BD081C;}
.socialIcon ul li.youtube a{color:#ff0000;}
.socialIcon ul li.youtube:hover a{background:#ff0000;}
.subscription-form-holder{margin:0 0 45px;}
.subscription-form-holder label{width:100%;font-size:20px;line-height:24px;color:#fff;}
.subscription-form-holder .subscription-form{min-width:600px;}
.subscription-form-holder .input-holder{width:calc(100% - 170px);}
.subscription-form-holder input[type="email"]{width:100%;height:60px;border:1px solid #fff;border-radius:3px;background:none;padding:5px 20px;color:#fff;}
.subscription-form-holder input::-webkit-input-placeholder {color:#fff;}
.subscription-form-holder input:-moz-placeholder {color:#fff;opacity:1;}
.subscription-form-holder input::-moz-placeholder {color:#fff;opacity:1;}
.subscription-form-holder input:-ms-input-placeholder {color:#fff;}
.subscription-form-holder input::-ms-input-placeholder {color:#fff;}
.subscription-form-holder input::placeholder {color:#fff;}
.subscription-form-holder input:focus{outline:none;box-shadow:none;}
.subscription-form-holder button[type="submit"]{width:160px;height:60px;background:#fff;color:#E37229;border:1px solid #fff;border-radius: 3px;transition: all ease-in-out 0.3s;}
.subscription-form-holder button[type="submit"]:hover{border-color:#E37229;background:#E37229;color:#fff;}
.footerLink{margin:0 0 40px;}
.footerLink ul li{margin:0 20px 10px;position: relative;}
.footerLink ul li:after{content:"";width:1px; height:calc(100% - 14px);position: absolute;top:7px;right:-20px;background:#fff;}
.footerLink ul li:last-child:after{display:none;}
.footerLink ul li a{ position: relative; z-index: 999; font-size: 20px; line-height: 31px; font-weight: normal; color: #fff; text-transform: capitalize; text-decoration: none; opacity: 1; transition: all ease-in-out .3s;}
.footerLink ul li a:hover{opacity: 1;color: #FD6F21;}
.footerBtm{position: relative; border-top: 2px solid #fff; padding: 30px 45px; text-align: center;}
.copyright{font-size: 20px; line-height: 24px; font-weight: 300;margin-bottom: 0px;color:#fff;}
.footerBtm .circle { position: absolute; top: -15px; right: 0; left: 0; margin: auto;}
.footerLink ul li a:hover:before{background: #FD6F21;}
.footer .asoft-logo{width:60px;position: absolute;right:0;bottom:0;}
/* content right to left */
.rtl .subscription-form-holder label{text-align: right;}
.rtl .footerLink ul{flex-direction: row-reverse;}
.rtl .footerLink ul li:after{right: auto; left: -20px;}
.rtl .subscription-form-holder input[type="email"]{text-align: right;}
/* MEDIA QUERY */
@media (max-width: 1699px) {
	.footer{padding: 50px 0 0;}
	.footerLogo{margin-bottom: 35px;}
	.footerLogo .outfit-p {font-size: 14px;}
	.socialIcon{margin-bottom: 80px;}
	.footerLogo img{width: 150px;}
	.socialIcon ul li a{width: 50px; height: 50px;} 
	.footerLink ul li a{ font-size: 18px; line-height: 24px;}
	.copyright{font-size: 14px; line-height: 18px; }
	.footerBtm .circle { top: -11px;}
	.socialIcon ul li a svg {font-size: 20px;}
}
@media (max-width:1399px) {
	.footerBtm .circle { top: -9px;}
	.wrapFooter .bar {width: 140px;}
	.footerLink ul li a {font-size: 16px;line-height: 22px;}
	.socialIcon {margin-bottom: 55px;}
}
@media (max-width: 1199px) {
	.wrapFooter .bar {width: 90px;}
}
@media (max-width:991px) {
	.socialIcon ul, .footerLink ul{flex-wrap: wrap;}
	.socialIcon {margin-bottom: 50px;}
}
@media(max-width:767px){
	.subscription-form-holder .subscription-form{min-width:100%;}
}
@media (max-width:667px) {
	.socialIcon ul, .footerLink ul{flex-wrap: wrap;}
	.socialIcon ul li a{margin-bottom: 15px;}
	.socialIcon {margin-bottom: 30px;}
	.socialIcon ul li{margin: 0px 10px;}
	.socialIcon ul li:not(:last-child){margin-right: inherit;}
}
@media (max-width:576px){
	.subscription-form-holder .input-holder{width:100%;margin:0 0 10px;}
	.subscription-form-holder button[type="submit"]{width:100%;}
}
@media (max-width: 479px) {
	.footer {padding: 25px 0px 0px 0px;}
	.socialIcon ul li a {width: 45px;height: 45px;}
	.subscription-form-holder input[type="email"] {font-size: 16px;}
}
@media (max-width: 319px) {
	.socialIcon ul li {margin: 0px 8px;}
	.socialIcon ul li a {width: 40px;height: 40px;}
	.socialIcon ul li a svg {font-size: 16px;}
}