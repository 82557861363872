.dashboard-sitePages .analyticDetailBox h4{margin-bottom: 6px;}
.tabBox > div{margin-bottom: 40px;}
.analyticGraph{margin-bottom: 30px;}
/* analyticDetailBox MARGN BOTTOM */ 
.analyticDetailBox .audiance {margin-bottom: 70px;}
.analyticDetailBox .likes {margin-bottom: 40px;}
/*  */
.boxUl{padding-left: 0px; margin-bottom: 19px;list-style: none;}
.boxUl li:not(:last-child){margin-bottom: 30px;}
.dashboard-sitePages .boxUl p.md-P{opacity: 0.8; line-height: 20px; margin-bottom: 1px;}
.analyticPer{display: flex; justify-content: space-between;}
.dotDiv{width: 45px;}
.orangeDot{display:block;width: 10px; height: 10px; border-radius: 50%; background-color: #FD6F21; }
.greyDot{display:block;width: 10px; height: 10px; border-radius: 50%; background-color: #3B3F57;}
.badgeFlex {display: flex;  flex-wrap: wrap; margin: 20px 0px;}
.badgeFlex .badge{margin-right: 12px; margin-bottom: 5px;}
.bg-secondary{ font-size: 14px; line-height: 18px; font-weight: 500; opacity: 1; background-color: #282C3C !important; color: #5D637B; height: 42px; display: flex; align-items: center; justify-content: center;}
.analyticResearch{text-align: center; margin: 120px auto auto auto;}
.Tab-sofia-p{ font-family: 'SofiaPro'; font-size: 20px; line-height: 26px; opacity: 0.4;}
.topRes{margin: auto auto 60px auto; max-width: 600px;}
.researchForm{background:#fff;border:1px solid #DBDBDB;border-radius: 10px; padding: 30px; max-width: 600px; margin: 0 auto; display: flex; align-items: center; justify-content: space-between; max-height: 80px;position:relative;}
.researchForm .researchIcon{margin-right:15px;position: absolute;right:0;top:50%;-o-transform: translateY(-50%);-ms-transform: translateY(-50%);-moz-transform: translateY(-50%);-webkit-transform: translateY(-50%);transform: translateY(-50%);}
.researchForm .researchIcon svg{color: #FC491E; font-size: 20px;}
.researchForm input{width:100%; background-color: transparent; border: 0px; color: #212529; height: 100%;font-size:16px;}
.researchForm input:focus-visible{outline: none; border: 0px;}
@media (max-width:1699px) {
    .tabBox > div{margin-bottom: 20px;}
    .orangeDot, .greyDot{ width: 6px; height: 6px; }
    /* analyticDetailBox MARGN BOTTOM */
    .analyticDetailBox .audiance {margin-bottom: 50px;}
    .analyticDetailBox .likes {margin-bottom: 20px;}
    .analyticResearch{ margin: 90px auto auto auto;}
    .boxUl{ margin-bottom: 9px;}
    .boxUl li:not(:last-child){margin-bottom: 15px;}
    .badgeFlex { margin: 10px 0px;}
    .badgeFlex .badge{margin-right: 8px;}
    .bg-secondary{ font-size: 12px; line-height: 16px; height: 25px;}
    .Tab-sofia-p{ font-size: 18px; line-height: 22px; }
    .topRes{margin: auto auto 40px auto; max-width: 400px;}
    .researchForm{ border-radius: 8px; padding: 20px; max-width: 400px; max-height: 60px;}
    .researchForm .researchIcon svg{ font-size: 15px;}
}
@media (max-width:575px) {
    .researchForm{padding: 10px;}
}