#channel-details{padding-bottom: 50px;}
.channel-details-header{margin-bottom: 25px;}
.channel-details-header .channel-details-coverPhoto{width: 100%; height: 300px; border-radius: 20px; overflow: hidden;}
.channel-details-coverPhoto img{width: 100%; height: 100%;object-fit: cover; object-position: center center;}
.about-channel .channel-profileImg{width: 150px; height: 150px; border-radius: 50%; overflow: hidden; box-shadow: #fc491ed4 0px 7px 29px 0px;}
.channel-profileImg img{width: 100%; height: 100%;} 
.about-channel .channel-userName{margin-left: 20px;}
.channel-userName h3{font-size: 35px;line-height: 38px; font-weight: 700; color: #FD6F21; margin-bottom: 0;}
.channel-info-modal-wrapper{margin-bottom: 20px;}
.channel-info-modal .modal-content{padding: 0; border-radius: 0; border-radius: 30px; background-color: #fff; border-color: transparent; overflow: hidden;height: 600px;}
.channel-info-modal .model-content-wrapper{ overflow-y: auto; padding: 30px; }
.channel-info-modal button, .channel-info-modal-wrapper button{padding: 0; background-color: transparent; border: none; color: #505050; font-size: 14px; line-height: 18px; font-weight: 400;}
.channel-info-modal button:hover, .channel-info-modal-wrapper button:hover{background-color: transparent; color: #FD6F21; }
.channel-info-modal button:focus-visible, .channel-info-modal .btn:active, .channel-info-modal-wrapper button:focus-visible, .channel-info-modal-wrapper .btn:active{background-color: transparent; box-shadow: none; color: #505050;}
.channel-info-modal .modal-header{padding-bottom: 0; border: none;}
.channel-info-modal .modal-title{margin-bottom: 0px;}
.channel-info-modal strong{color: #000; font-size: 22px; line-height: 25px; font-weight: 600; display: block; margin-bottom: 20px;}
.channel-info-modal .modal-title p{color: #FD6F21; font-size: 14px; line-height: 18px; font-weight: 300; margin-bottom: 10px;}
.channel-info-modal .modal-body{padding: 20px 0;}
.channel-info-modal .channel-details-modal-ul{list-style: none; margin: 0; padding: 0;}
.channel-details-modal-ul li{margin-bottom: 10px;}
.channel-info-modal .channel-social-Link{margin-left: 20px;}
.channel-info-modal .channel-social-Link span{font-size: 17px; line-height: 22px; font-weight: 600; color: #FD6F21;}
.channel-info-modal .channel-social-Link a{font-size: 17px; line-height: 22px; font-weight: 300;}
.channel-info-modal .channel-info-email-Link a{color: #000; padding: 10px; border-radius: 30px; background-color: rgba(253, 111, 33, 0.1);}
.channel-info-modal .channel-info-link a, .channel-info-modal .channel-info-link span {color: #FD6F21; font-weight: 300;}
.channel-info-modal .channel-social-icon{width: 30px; height: 30px;}
.channel-info-modal .modal-footer button{padding: 10px; border-radius: 30px; background-color: rgba(253, 111, 33, 0.1);}
.channel-info-modal .modal-footer button span, .channel-userName .Subscribe-btn span{margin-left: 8px;}
.channel-userName .Subscribe-btn{padding: 10px 20px; background-color: #FD6F21; border-radius: 30px; border-color: transparent; font-size: 14px; line-height: 18px; font-weight: 400;}
.channel-userName .Subscribe-btn:hover{background-color: transparent; border-color: #FD6F21; color: #FD6F21;}
.channel-details-btns button{min-width: auto;}
/* Content right to left */
.rtl .about-channel{flex-direction: row-reverse;}
.rtl .about-channel .channel-userName{margin-left: 0; margin-right: 20px;}
.rtl .channel-info-modal strong{text-align: right;}
.rtl .channel-details-modal-ul li{flex-direction: row-reverse;}
.rtl .channel-info-modal .channel-social-Link{margin-left: 0; margin-right: 20px;}
@media(max-width:1399px){
    .channel-details-header .channel-details-coverPhoto{height: 210px;}
    .about-channel .channel-profileImg { width: 140px; height: 140px;}
    .channel-userName h3 { font-size: 28px; line-height: 31px;}
}
@media(max-width:991px){
    #channel-details{padding-left: 15px;}
    .channel-details-header .channel-details-coverPhoto{height: 180px;}
    .channel-userName .Subscribe-btn { padding: 8px 15px;}
}
@media(max-width:575px){
    .channel-details-header .channel-details-coverPhoto { height: 120px;}
    .about-channel .channel-profileImg { width: 100px; height: 100px;}
    .channel-userName h3 { font-size: 25px; line-height: 28px;}
    .about-channel .channel-userName { margin-left: 13px;}
    .channel-info-modal-wrapper { margin-bottom: 10px;}
    .channel-info-modal .channel-social-icon{font-size: 17px;}
    .channel-info-modal .model-content-wrapper{padding: 20px;}
}
@media(max-width:380px){
    .about-channel .channel-profileImg { width: 85px; height: 85px;}
    .channel-userName h3 { font-size: 22px; line-height: 25px;}
    .channel-userName .Subscribe-btn { padding: 6px 9px; font-size: 13px; line-height: 17px;}
    .channel-info-modal .channel-social-Link {margin-left: 8px;}
    .channel-info-modal .channel-social-icon { width: 25px; height: 25px;}
    .channel-info-modal .channel-social-Link a {font-size: 13px; line-height: 17px;}
    .channel-info-modal .channel-social-Link span { font-size: 16px; line-height: 19px;}
    .channel-info-modal .channel-info-email-Link a{padding: 5px;}
    .channel-info-modal .channel-info-link span {font-size: 13px; line-height: 17px; }
    .channel-info-modal .modal-footer button{padding: 5px; font-size: 13px; line-height: 17px;}
}
@media(max-width:325px){
    .channel-userName h3 { font-size: 19px; line-height: 23px;}
    .channel-info-modal-wrapper button{font-size: 13px; line-height: 17px;}
}
@media(max-width:300px){
    .about-channel .channel-profileImg { width: 75px; height: 75px;}    
    .channel-info-modal-wrapper { margin-bottom: 0px;}
    .channel-userName h3 { font-size: 17px; line-height: 20px;}
}