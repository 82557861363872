.theme-form{height:calc(100vh - 60px);background:#F2F3F8;}
.dashboard-page .theme-form{height:calc(100vh - 70px);}
.theme-form .auth-login-logo{width:78px;position: absolute;left:40px;top:2px;}
.form-overlay{background-color: rgb(13 15 23 / 90%); height: 100%; width: 100%; position: fixed; top: 0px; }
.formContainer{padding: 0px 40px; display: flex; flex-direction: column; justify-content: center;}
.formLogo{padding: 30px 0px 5px 0px;}
.formLogo > a {display: inline-block}
.formLogo img{width: 121px;}
.formGroup{margin-bottom: 10px;}
.biiview-form{position: relative; z-index: 999;height:100%;overflow-y: auto;padding:50px 0;}
.formStyle{min-width: 600px; background: #fff; border-radius: 30px; padding: 50px 60px;  text-align: center;margin: auto; }
.wrapForm{ margin: auto;  display: flex;  align-items: center;  flex-direction: column;}
.card-details .wrapForm {width: 500px;flex-direction:initial}
.card-details .orange-btn {margin: 0 auto;}
.form-control{margin-bottom: 0px;}
.form-label{color:#505050;}
.form-label, .form-control{ font-size: 13px; line-height: 16px; font-weight: 400; margin-bottom: 10px;text-align: left;width: 100%;}
.form-control { background: #fff; border-radius: 10px; border: 1px solid #E1E1E1; height: 54px; color: #212529;}
.form-control::-webkit-input-placeholder {color:#7f7f7f;}
.form-control:-moz-placeholder {color:#7f7f7f;opacity:1;}
.form-control::-moz-placeholder {color:#7f7f7f;opacity:1;}
.form-control:-ms-input-placeholder {color:#7f7f7f;}
.form-control::-ms-input-placeholder {color:#7f7f7f;}
.form-control::placeholder {color:#7f7f7f;}
.form-control:focus {  border: 1px solid #E1E1E1;}
.inputDiv{ height:54px;background: #fff;border-radius: 10px; border: 1px solid #E1E1E1; height: 62px; color: #fff; display: flex; align-items: center;padding-right: 20px;}
.inputDiv input{background: transparent;margin: 0px;border: 1px solid transparent;}
.inputDiv svg{color: #FD6F21;}
.inputDiv:hover{ outline: #FD6F2180; border-color: #FD6F2180;}
.inputDiv input.form-control:focus, .inputDiv input.form-control:focus-visible {box-shadow: none; outline: none; border-color: transparent; border: 0px;color: #000;}
.form-control:focus, .form-control:focus-visible {box-shadow: none; outline: #FD6F2180; border-color: #FD6F2180}
.formStyle h2{ font-size: 30px; line-height: 34px; font-weight: 600; margin-bottom: 20px;color:#505050;}
.theme-form p{ font-size: 16px; line-height: 20px; font-weight: 400; color: #505050;}
.theme-form p.link:hover{color:#FD6F21; }
.theme-form .outer-p{color: #505050;}
.formHeader{margin-bottom: 30px; padding-bottom: 30px; border-bottom: 1px solid #B1B1B1;}
.formStyle .submit-btn-holder{padding:40px 0 0;}
.formStyle .orange-main-button{margin:0;padding: 22px 78px;}
.theme-capcha {padding-top: 10px; display: flex; flex-direction: column; align-items: center;}
.theme-capcha > div {margin-bottom: 8px;}
.end-mb{margin-bottom: 60px;}
.forgot{max-width: 480px;}
/* PACKAGE */
.formStyle.package{max-width: 1742px;}
.formGroup .text-danger{display: block; text-align: left; font-size: 12px; line-height: 12px;}
.troubbleText{max-width: 460px;}
.formGroup .eye-icon {position: absolute;right: 14px;top: 50%;transform: translateY(-50%);-webkit-transform: translateY(-50%); -o-transform: translateY(-50%);-moz-transform: translateY(-50%);-ms-transform: translateY(-50%);}
.formGroup input[type="password"] { padding: 0.375rem 40px 0.375rem .75rem;}
.wrapForm .agree-text .link{font-weight: 400;}
.modal-content .react-time-picker__wrapper{border: 1px solid #E1E1E1; border-radius: 10px;}
/* content right to left */
.rtl .form-control, .rtl .form-label{text-align: right;}
.rtl .card-info-from-label{flex-direction: row-reverse;}
.rtl .card-info-from-label span{margin-right: 5px;}
.rtl .formGroup .eye-icon{right: auto; left: 15px;}
/* MEDIA QUERY */
@media (max-width:1899px){
    .theme-form .auth-login-logo{width:60px;left:25px;}
}
@media (max-width: 1699px) {
    .formContainer{padding: 0px 35px;}
    .formLogo{padding: 25px 0px 40px 0px;}
    .formGroup{margin-bottom: 10px;}
    .formStyle{min-width: 450px; border-radius: 15px; padding: 28px 30px;}
    .inputDiv{padding-right: 10px;}
    .formStyle h2{font-size: 25px; line-height: 30px;}
    .formHeader{margin-bottom: 30px;}
    .formStyle .orange-btn{margin-top: 20px;}
    .end-mb{margin-bottom: 30px;}
    .theme-capcha {padding-top: 8px;}
    /* .theme-form .wrapForm {padding: 80px 0px 50px;} */
}
@media (max-width: 1520px){
    .formStyle .orange-btn {padding: 12px 45px;}
}
@media (max-width: 1399px) {
    .formHeader{padding-bottom: 20px;}
    .formHeader { margin-bottom: 20px;}
    .formLogo{padding: 15px 0px 20px 0px;}
    .forgot{max-width: 380px;}
    .theme-form .auth-login-logo{width:60px;left:30px;}
    .formStyle .submit-btn-holder{padding:30px 0 0}
}
@media (max-width: 767px) {
    .wrapForm{width: 100%;}
    .formStyle{min-width: 80%; border-radius: 15px; padding: 28px 30px; }
    .formContainer{padding: 0px 25px;}
    .formLogo{padding: 15px 0px 20px 0px;}
    .theme-form .auth-login-logo{width:40px;left:10px;}
    .formStyle .submit-btn-holder{padding:15px 0 0}
 }
 @media (max-width: 479px){
    .formStyle {width: 100%;}
    .theme-form .auth-login-logo{width:30px;left:5px;}
    .theme-capcha iframe{-o-transform: scale(0.8);-ms-transform: scale(0.8);-moz-transform: scale(0.8);-webkit-transform: scale(0.8);transform: scale(0.8);}
    .formStyle .submit-btn-holder{padding: 0}
 }