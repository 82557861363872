.blog-details-sec {padding: 30px 0 100px 0;}
.blog-details-sec .breadcrum {margin-bottom: 40px;}
.blog-details-sec .breadcrum p {font-size: 20px;font-weight: 500;color:#505050;}
.blog-details-sec .breadcrum .prev {color:#505050;}
.blog-details-sec .heading {margin-bottom: 20px;}
.blog-details-sec .heading h1 {max-width: 1312px;}
.blog-details-sec .post-details {margin-bottom: 60px;}
.blog-details-sec .post-details p {font-size: 20px;font-weight: 500;color: #505050;}
.blog-details-sec .post-details .author-name {margin-right: 150px;}
.blog-details-sec .post-details .auther-date p {font-size: 20px;line-height: 24px}
.blog-details-sec .post-details a {color: #fd6f21;}
.social-icons-blog-post {transition: all 0.9s ease-in-out;}
.social-icons-blog-post button{margin:0 5px 5px 0;}
.blog-details-sec .post-img {margin-bottom: 60px;}
.blog-details-sec .post-details h4 {font-size: 32px;line-height: 40px;font-weight: 500;margin-bottom: 25px;max-width: 974px;}
.blog-details-sec .post-details h2{font-size: 30px; line-height: 34px;}
.blog-details-sec .post-details p, .blog-details-sec .post-details ul li {font-size: 18px;line-height: 26px;font-weight: 400;margin-bottom: 30px;max-width: 974px;}
.blog-details-sec .post-details ul li{margin-bottom:15px;}
.blog-details-sec .post-details p strong {display: inline-block;}
.blog-details-sec .post-details .img2 {margin-bottom: 60px;}
.blog-details-sec .post-details h5 {font-size: 30px;font-weight: 700;line-height: 48px;color: #030C16;font-weight:500;margin-bottom: 20px;}
.blog-details-sec .blog-names {padding: 90px 0;display: flex;justify-content: space-between;border-bottom: 1px solid rgba(225, 225, 225, 0.15);margin-bottom: 80px;}
.blog-details-sec .blog-names svg {color: rgba(225, 225, 225, 0.4);cursor: pointer;}
.blog-details-sec .blog-names .wrapper1 p {margin-left: 25px;}
.blog-details-sec .blog-names .wrapper2 p {margin-right: 25px;color: rgba(225, 225, 225, 0.6);}
.blog-details-sec .blog-names .wrapper2 p:hover,
.blog-details-sec .blog-names .wrapper1 p:hover {color: #fd6f21;}
.blog-details-sec .blog-names svg:hover {color: #fd6f21;}
.blog-details-sec .blog-names p {max-width: 300px;font-size: 26px;line-height: 30px;color: rgba(225, 225, 225, 0.4);}
.blog-details-sec .related-blogs h4,
.blog-details-sec{font-size: 32px;line-height: 40px;font-weight: 600;margin-bottom: 40px;}
.blog-details-sec .related-blogs .col-lg-4 {margin-bottom: 40px;}
.related-blogs .related-blogs-wrapper .img {margin-bottom: 30px;height: 250px;}
.related-blogs .related-blogs-wrapper .blog-date {font-size: 16px;line-height: 20px;font-weight: 400;color: #505050;}
.related-blogs .related-blogs-wrapper .blog-heading {font-size: 20px;line-height: 25px;font-weight: 600;color:#FD6F21;}
.related-blogs .related-blogs-wrapper a.blog-heading:hover{text-decoration:underline;}
.post-details .img2 {height: 750px;}
.blog-details-sec .heading-sec {font-size: 24px;line-height: 30px;font-weight: 600;padding-bottom: 40px;border-bottom: 1px solid rgba(225, 225, 225, 0.15);margin-bottom: 20px;}
.related-posts {margin-bottom: 80px;margin-top: 100px;}
.related-posts .related-post {margin-bottom: 47px;}
.related-posts .related-post .img {height: 109px;}
.related-posts .related-post .img img{border-radius: 10px;width: 168px;}
.related-posts .related-post .content {margin-left: 30px;width: calc(100% - 170px);}
.related-posts .related-post .content .related-post-date {font-size: 16px;line-height: 20px;font-weight: 400;color: #505050;}
.related-posts .related-post .content .related-post-description {font-size: 20px;line-height: 25px;font-weight: 600;color: #FD6F21;}
.related-posts .related-post .content a.related-post-description:hover {text-decoration: underline;}
.popular-tags {margin-bottom: 80px;}
.popular-tags .tag {color: #fff;font-size: 16px;line-height: 20px;font-weight: 400;text-decoration: none;background-color: #1e222f;border-radius: 26px;margin-bottom: 12px;margin-right: 12px;padding: 15px 30px;}
.catagories {margin-bottom: 80px;}
.catagories .category {margin-bottom: 40px;}
.catagories .category .category-name {font-size: 24px;line-height: 30px;font-weight: 500;text-decoration: none;color: #FD6F21;max-width: calc(100% - 70px);}
.catagories .category .category-name svg {font-size: 16px;margin-right: 10px;width: 16px;}
.catagories .category .category-name span {max-width: calc(100% - 26px);text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
.catagories .category .category-count {font-size: 14px;padding: 1px 9px;background-color: #2f3140;border-radius: 26px;min-width: 30px;height: 30px;display: flex;justify-content: center;align-items: center;max-width: 55px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;text-align: center;margin-left: 15px;color:#fff;}
.social-media .social.instagram {background-color: #c01976;}
.social-media .social.facebook {background-color: #475993;}
.social-media .social.twitter {background-color: #76a9ea;}
.social-media .social.pinterest {background-color: #cb2027;}
.social-media .social {border-radius: 4px;padding: 26px 30px;}
.social-media .social .icon svg {font-size: 32px;}
.social-media .social .details {margin-left: 25px;}
.social-media .social .details span {font-size: 20px;line-height: 25px;font-weight: 400;}
.social-media .col-sm-6 {margin-bottom: 40px;}
.related-blogs .col-md-4 {margin-bottom: 20px;}
.blog-comment-form{margin:0 0 30px;}
.blog-comment-form textarea{border: none; border-bottom: 1px solid #C4C1C1; border-radius: 0px; margin-left: 25px; resize:none; padding-right: 80px;}
.blog-comment-form textarea:focus{border: none;  border-bottom: 1px solid #FD6F21;}
.blog-comment-form button{margin-top:0;}
.comment-wrapper{margin-bottom: 50px; display: flex;}
.blog-comment-form span{font-size: 15px; line-height: 18px; font-weight: 400; color: #FD6F21;}
.blog-comment-form .user-details p{margin-bottom: 0;}
.blog-comment-form .userImg{overflow: hidden; box-shadow: 0 0 10px rgba(253, 111, 33, 0.8);}
.blog-comment-form .comments-dropdown-wrapper{display: flex; justify-content: flex-end;}
.blog-comment-form .comments-dropdown-wrapper span{font-size: 22px; line-height: 25px;}
.blog-comment-form .comment-body{font-size: 14px; line-height: 18px; font-weight: 300; margin-left: 20px;}
.blog-comment-form h4{font-size: 16px; line-height: 20px; font-weight: 500; margin-bottom: 27px; color: #FD6F21; position: relative;}
.blog-comment-form h4::before{content: ""; width: 30px; height: 2px; background-color: #fd6f21; position: absolute; left: 0; bottom: 0;}
.blog-comment-form .user-comment-wrapper{display: flex; margin-bottom: 35px;}
.blog-comment-form .comment-box { width: 100%; flex: 1; position: relative;}
.comment-box .btn-holder{position: absolute; top: 0; right: 0;}
.blog-comment-form .comment-time{font-size: 14px; line-height: 17px; color: #A2A2A2; margin-left: 27px;}
.text-show-btn{text-decoration: none; font-size: 14px; line-height: 24px; font-weight: 400; color: #fd6f21; padding: 0; border: none; background-color: transparent;}
.blog-comment-form .user-time-wrapper{margin-bottom: 10px;}
.blog-comment-form .empty-comment-body{font-size: 14px; line-height: 17px; font-weight: 300; color: #FD6F21;}
.share-btn .cross-btn{padding: 0; border: none; position: absolute;top: -18px;right: -3px;}
.share-btn .blog-share-btn{background-color: #030C16; color: #fff; border: 1px solid #030C16; transition: all 0.3s ease-in-out;}
.share-btn .blog-share-btn:hover, .share-btn .blog-share-btn:active{background-color: transparent; color: #030C16;}
.popular-tags-wrapper .no-record-found{max-height: 50px; margin-right: 12px;}
/* content right to left */
.rtl .related-posts .related-post{flex-direction: row-reverse;}
.rtl .related-posts .related-post .content{margin-left: 0; margin-right: 30px;}
.rtl .related-posts .related-post .content .related-post-date{text-align: right;}
.rtl .related-posts .related-post .content .related-post-description{text-align: right; display: block;}
.rtl .blog-details-sec .breadcrum p{text-align: right;}
.rtl .blog-details-sec .heading h1{text-align: right;}
.rtl .blog-details-sec .post-details{flex-direction: row-reverse;}
.rtl .blog-details-sec .ck.ck-content {display: flex;flex-direction: column;align-items: flex-end;}
.rtl .blog-details-sec .heading-sec{text-align: right;}
.rtl .popular-tags-wrapper{flex-direction: row-reverse;}
.rtl .blog-details-sec .related-blogs h4{text-align: right;}
.rtl .related-blogs h4:before{left: auto; right: 0;}
.rtl .related-blogs .related-blogs-wrapper .blog-date{text-align: right;}
.rtl .related-blogs .related-blogs-wrapper .blog-heading{text-align: right; display: block;}
@media (max-width: 1599px) {
  .blog-details-sec .breadcrum p {font-size: 18px;}
  .blog-details-sec .breadcrum {margin-bottom: 20px;}
  .blog-details-sec .post-details,
  .blog-details-sec .post-img,
  .blog-details-sec .post-details p,
  .blog-details-sec .post-details .img2,
  .blog-details-sec .post-details h5 {margin-bottom: 40px;}
  .blog-details-sec .post-details p {margin-bottom: 30px;}
  .related-posts {margin-top: 80px;}
  .blog-details-sec .post-details p {font-size: 22px;line-height: 28px;}
  .blog-details-sec .post-details .author-name {margin-right: 100px;}
  .blog-details-sec .post-img {height: 600px;}
  .blog-details-sec .post-details h4 {font-size: 30px;line-height: 38px;}
  .blog-details-sec .post-details h5 {font-size: 28px;line-height: 44px;padding-left: 30px;}
  .blog-details-sec .blog-names {padding: 70px 0;}
  .blog-details-sec .blog-names {margin-bottom: 60px;}
  .blog-details-sec .related-blogs h4 {margin-bottom: 30px;}
  .related-blogs .related-blogs-wrapper .img {height: 190px;margin-bottom: 20px;}
  .related-blogs .related-blogs-wrapper .blog-date {font-size: 15px;line-height: 19px;}
  .related-blogs .related-blogs-wrapper .blog-heading {font-size: 18px;line-height: 23px;}
  .blog-details-sec .heading-sec {font-size: 22px;line-height: 28px;padding-bottom: 30px;margin-bottom: 20px;}
  .related-posts .related-post .img {height: 100px;}
  .related-posts .related-post .content {margin-left: 20px;width: calc(100% - 150px);}
  .related-posts .related-post .content .related-post-date {font-size: 15px;line-height: 19px;}
  .related-posts .related-post .content .related-post-description {font-size: 18px;line-height: 23px;}
  .related-posts .related-post {margin-bottom: 35px;}
  .related-posts,
  .popular-tags,
  .catagories {margin-bottom: 60px;}
  .catagories .category .category-name {font-size: 22px;line-height: 28px;}
  .catagories .category .category-name svg {font-size: 14px;}
  .catagories .category {margin-bottom: 30px;}
  .social-media .social {padding: 20px 25px;}
  .social-media .social .details span {font-size: 18px;line-height: 23px;}
  .social-media .social .details {margin-left: 20px;}
  .social-media .social .icon svg {font-size: 30px;}
  .popular-tags .tag {padding: 12px 24px;}
  .post-details .img2 {height: 600px;}
}

@media (max-width: 1399px) {
  .blog-details-sec .post-img,
  .post-details .img2 {height: 500px;}
  .related-blogs .related-blogs-wrapper .img {height: 160px;}
  .social-media .social {padding: 16px 14px;}
  .related-posts .related-post .img {height: 90px;}
  .related-posts .related-post .content {width: calc(100% - 140px);}
  .catagories .category .category-name {font-size: 18px;line-height: 24px;}
}

@media (max-width: 1199px) {
  .blog-details-sec .post-details p {font-size: 20px;line-height: 26px;}
  .blog-details-sec .post-details,
  .blog-details-sec .post-img,
  .blog-details-sec .post-details p,
  .blog-details-sec .post-details .img2,
  .blog-details-sec .post-details h5 {margin-bottom: 30px;}
  .blog-details-sec .post-img,
  .post-details .img2 {height: 400px;}
  .blog-details-sec .post-details h4 {font-size: 28px;line-height: 35px;}
  .blog-details-sec .post-details h5 {font-size: 26px;line-height: 40px;padding-left: 20px;}
  .blog-details-sec .blog-names {margin-bottom: 40px;}
  .blog-details-sec .blog-names .wrapper1 p {margin-left: 15px;}
  .blog-details-sec .blog-names .wrapper2 p {margin-right: 15px;}
  .related-blogs .related-blogs-wrapper .img {height: 120px;}
  .related-blogs .related-blogs-wrapper .blog-date {font-size: 14px;line-height: 18px;}
  .related-blogs .related-blogs-wrapper .blog-heading {font-size: 16px;line-height: 20px;}
  .blog-details-sec .heading-sec {padding-bottom: 20px;}
  .related-posts .related-post .content .related-post-date {font-size: 14px;line-height: 18px;}
  .related-posts .related-post .content .related-post-description {font-size: 16px;line-height: 20px;}
  .related-posts .related-post .img {height: 80px;}
  .popular-tags .tag {padding: 10px 20px;}
  .popular-tags .tag {font-size: 14px;line-height: 18px;}
  .catagories .category {margin-bottom: 20px;}
  .social-media .social {padding: 12px 10px;}
  .social-media .social .details {margin-left: 10px;}
  .social-media .col-sm-6 {margin-bottom: 20px;}
  .social-media .social .icon svg {font-size: 28px;}
  .social-media .social .details span {font-size: 16px;line-height: 20px;}
}
@media (max-width: 991px) {
  .blog-details-sec .post-img,
  .post-details .img2 {height: auto;}
  .related-blogs .related-blogs-wrapper .img {height: auto;}
  .related-posts {margin-top: 40px;}
  .related-posts,
  .popular-tags,
  .catagories {margin-bottom: 40px;}
}
@media (max-width: 767px) {
  .blog-details-sec .post-details .author-name {margin-right: 40px;}
  .blog-details-sec .blog-names .wrapper1 p {margin-left: 7px;}
  .blog-details-sec .blog-names .wrapper2 p {margin-right: 7px;}
}
@media (max-width: 575px) {
  .blog-details-sec .breadcrum p {font-size: 16px;line-height: 20px;}
  .blog-details-sec .post-details .author-name {margin-right: 20px;}
  .blog-details-sec .post-details h4 {font-size: 24px;line-height: 30px;}
  .blog-details-sec .post-details p {font-size: 18px;line-height: 24px;}
  .blog-details-sec .post-details h5 {font-size: 22px;line-height: 32px;padding-left: 15px;}
  .blog-details-sec .blog-names {margin-bottom: 20px;}
  .blog-details-sec .blog-names {padding: 40px 0;}
  .related-posts,
  .popular-tags,
  .catagories {margin-bottom: 20px;}
  .related-posts {margin-top: 0px;}
  .popular-tags .tag {padding: 8px 16px;margin-bottom: 8px;margin-right: 8px;}
  .catagories .category {margin-bottom: 10px;}
  .catagories .category .category-name {font-size: 18px;line-height: 22px;}
  .blog-details-sec .post-details .share-btn button {margin-left: 12px;}
  .blog-comment-form textarea{margin-left: 0; padding-right: 50px;}
}
@media (max-width: 479px) {
  .blog-details-sec .post-details .share-btn {flex-direction: column-reverse;width: 100%;}
  .blog-details-sec .post-details .share-btn button {margin-bottom: 10px;}
  .blog-details-sec .post-details .share-btn button {margin-left: 0px;}
  .blog-details-sec .post-details .share-btn .social-icons-blog-post button {margin-left: 10px;}
}
@media (max-width:367px) {
.blogs-sec .related-posts3 .related-post { flex-wrap: wrap;}
.related-posts .related-post .content{width: 100%; margin-left: 10px;}
}