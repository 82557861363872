.profile-sec {margin-bottom: 50px;}
.profile-sec .table-big-boy {background: #fff 0% 0% no-repeat padding-box;border-radius: 20px;}
.profile-sec .table-big-boy button {max-height: 72px;}
.profile-sec .table-big-boy label {font: normal normal normal 16px/20px Outfit;letter-spacing: 0px;color: #505050;margin: 0 0 10px;}
.profile-sec .card-header{background-color: transparent;}
.profile-sec .card-header-title {border-bottom: 0;background:transparent;}
.profile-sec .input-file-btn-holder {position: relative;width: 146px;height: 50px;margin: 0 auto 50px;border-radius: 10px;overflow: hidden;}
.profile-sec .input-file-btn-holder input {width: 100%;height: 100%;display: block;font-size: 0;opacity: 0;position: relative;z-index: 1;cursor: pointer;}
.profile-sec .input-file-btn-holder .input-file-btn {width: 100%;height: 100%;position: absolute;left: 0;top: 0;border:2px solid #FD6F21;background: #FD6F21;color: #fff;font-size: 14px;line-height: 18px;overflow:hidden;border-radius:10px;display: flex;justify-content: center;align-items: center;font-weight: bold;transition:all ease-in-out 0.3s;}
.profile-sec .upload-img .author img {width: 100px;height: 100px;margin-bottom: 20px;border-radius: 50%;box-shadow: #000 0px 2px 10px 0px;object-fit: cover;}
.profile-sec .input-file-btn-holder:hover input ~ .input-file-btn{background:#fff;color:#FD6F21;}
.profile-sec .upload-img {padding-block: 50px;}
.profile-sec .upload-img .card-title {color: #fff;font-size: 24px;line-height: 30px;max-width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
/* content right to left */
.rtl .profile-sec .table-big-boy label{text-align: right; width: 100%; display: flex; flex-direction: row-reverse;}
/*responsiveness*/
@media (max-width: 1399.98px) {
  .profile-sec label {font-size: 18px;line-height: 22px;}
}
@media (max-width: 1199.98px) {
  .profile-sec .input-file-btn-holder {margin: 0 auto;}
}
@media (max-width: 575.98px) {
  .profile-sec label {font-size: 16px;line-height: 20px;}
}
@media (max-width: 319.98px) {
  .profile-sec .input-file-btn-holder {width: 130px;}
  .profile-sec label {font-size: 14px;line-height: 18px;}
}
