.query-sec {padding: 160px 0;background-color: rgba(31, 33, 47, 1);}
.query-sec h1 {color:#fff;}
.query-sec h3 {color: #fff;font-weight: normal;font-size: 30px;font-family: 'SofiaPro';}
.getintouch-sec {padding: 100px 0;}
.getintouch-sec .socialIcon ul li a svg {font-size: 20px;}
.getintouch-sec .help-sec {padding: 0;}
.getintouch-sec h1 {margin-bottom: 50px;font-weight: 600;}
.getintouch-sec p {font-size: 24px;color: #505050;line-height: 36px;margin-bottom: 40px;}
.getintouch-sec .details {max-width: 600px;}
.getintouch-sec .details a {color: #fff;text-decoration: none;font-size: 20px;font-weight: 500;}
.getintouch-sec .details a svg path {fill: #B4B4B4;}
.getintouch-sec .details .detail {padding: 24px 30px;background-color: #1B1D27;box-shadow: 0px 5px 50px #06369F1A;border-radius: 12px;margin-bottom: 20px;cursor: pointer;transition: all 0.3s ease-in-out;display: block;}
.getintouch-sec .details .detail:hover {background-color: #fd6f21;color: #fff;}
.getintouch-sec .details .detail:hover svg path {fill: #fff;}
.getintouch-sec .details .detail span {margin-right: 20px;}
.getintouch-sec .socialIcon {margin-bottom: 35px;}
.getintouch-sec .socialIcon ul {padding: 0;justify-content: flex-start;}
.getintouch-sec .socialIcon ul li:not(:last-child) {margin-right: 10px;}
.getintouch-sec .socialIcon ul li a {width: 50px;height: 50px;background-color: #FA6400;border: 1px solid #FA6400;}
.getintouch-sec .socialIcon ul li a:hover {background-color: #FE6C30;border: 1px solid #FE6C30;}
.contactus-map {max-width: 600px;height: 370px;}
.contactus-map iframe {border-radius: 20px;width: 100%;height: 100%;}
/* content right to left */
.rtl .query-sec h1{text-align: right;}
.rtl .query-sec h3{text-align: right;}
.rtl .getintouch-sec h1{text-align: right;}
.rtl .getintouch-sec p{text-align: right;}
.rtl .getintouch-sec .details .detail{display: flex; flex-direction: row-reverse;}
.rtl .getintouch-sec .details .detail span{margin-right: 0; margin-left: 20px;}
/*media queries*/
@media (max-width: 1699px) {
    .query-sec h3 {font-size: 26px;}
    .getintouch-sec p {font-size: 22px;line-height: 32px;}
    .getintouch-sec .details a {font-size: 18px;}
}
@media (max-width: 1599px) {
    .query-sec,
    .getintouch-sec {padding: 100px 0;}
    .query-sec h3 {font-size: 24px;}
    .getintouch-sec .details .detail {padding: 20px 25px;}
    .getintouch-sec p {font-size: 22px;line-height: 32px;}
    .getintouch-sec h1 {margin-bottom: 40px;}
}
@media (max-width: 1399px) {
    .query-sec, .getintouch-sec {padding: 70px 0;}
    .getintouch-sec h1 {margin-bottom: 30px;}
    .getintouch-sec .details a {font-size: 18px;}
    .query-sec h3 {font-size: 19px;line-height: 24px;}
    .getintouch-sec p {font-size: 18px;line-height: 28px;}
}
@media (max-width: 991px) {
    .query-sec, .getintouch-sec {padding: 50px 0;}
    .query-sec h1 {margin-bottom: 20px !important;}
    .getintouch-sec .details,
    .contactus-map {max-width: 100%;}
    .contactus-map {margin-bottom: 40px;}
}
@media (max-width: 575px) {
    .query-sec, .getintouch-sec {padding: 30px 0;}
    .query-sec h3 {font-size: 22px;}
    .getintouch-sec h1 {margin-bottom: 20px;}
    .getintouch-sec .details a {font-size: 16px;line-height: 20px;}
    .help-sec .form-wrapper {border-radius: 20px;}
    .getintouch-sec p {font-size: 19px;line-height: 24px;}
}